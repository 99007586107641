import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

const SubCategoryList = ({ categoryData, onSubCategoryChange }) => {
    const { category_id, gender_id, segment_id, sub_category_id } = useParams();

    const [selectedSubCategory, setSelectedSubCategory] = useState(sub_category_id);

    // Handle radio button change
    const handleRadioChange = (subCategoryId) => {
         // Only use jQuery if necessary
         if (window.jQuery) {
            window.jQuery('.filter-option').find('.filters__item-title').find('.filters__count').text('');
            // window.jQuery('.filter-option').find('.filters__item-title').find('.filters__item-title-text').text('Sort By Price');
            window.jQuery('.filters__listings').find('ul li').each(function() {
                window.jQuery(this).removeClass('active');
            });
        }
        setSelectedSubCategory(subCategoryId);
        onSubCategoryChange(subCategoryId);
        
    };


    // Update selected sub-category based on the URL parameters
    useEffect(() => {
        setSelectedSubCategory(sub_category_id || '');
        // console.log(sub_category_id,'useEffect');
    }, [sub_category_id]);
// console.log(sub_category_id,'sub_category_id');
// console.log(selectedSubCategory,'selectedSubCategory');
// console.log(categoryData,'categoryData');
    return (
        <div className="sub-category-list">
            <h4>Sub Category</h4>
            <ul>
                {categoryData.map((item) => (
                    <li
                        key={item.sub_category_id}
                        className={`${
                            selectedSubCategory == item.sub_category_id || sub_category_id == item.sub_category_id
                                ? 'active'
                                : ''
                        }`}
                    >
                        <label>
                            <input
                                type="radio"
                                name="subCategory"
                                value={item.sub_category_id}
                                checked={selectedSubCategory == item.sub_category_id}
                                onChange={() => handleRadioChange(item.sub_category_id)}
                            />
                            {item.sub_category_name}
                        </label>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default SubCategoryList;
