import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import api from '../../api';
import { useAuth } from '../../context/AuthContext';
import { useCart } from '../../context/CartContext';
import CurrencySelector from '../currencySelector/CurrencySelector'; // Import the CurrencySelector component
import { countries } from 'countries-list';


const Cart = () => {
    const { cartCount, addItemToCart, removeItemFromCart } = useCart();
    const navigate = useNavigate();
    const { apiToken, userRole, logout } = useAuth();

    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [cartData, setCartData] = useState([]);
    const [error, setError] = useState(null);
    const [totalAmount, setTotalAmount] = useState(0);
    const [currencyData, setCurrencyData] = useState({});
    const [couponCode, setCouponCode] = useState('');
    const [discountedAmount, setDiscountedAmount] = useState(0);
    const [isCouponApplied, setIsCouponApplied] = useState(false); // New state variable
    const [selectCoupon, setSelectCoupon] = useState(0); // New state variable
    const [couponOwnerId, setCouponOwnerId] = useState(0); // New state variable

    const fetchCartDetails = async () => {
        try {
            const order_num = localStorage.getItem('order_num');
            const response = await api.get('/cart/get_cart_details', {
                params: {
                    api_token: localStorage.getItem('apiToken'),
                    order_num,
                },
            });
            console.log(response,'response')
             if (response.data.status === 401) {

                console.log('401');
                logout();
                navigate('/home');
            }
           else if (response.status === 200) {
                setCartData(response.data.cart_items);
                const total = response.data.cart_items.reduce((acc, item) => acc + parseFloat(item.total), 0);
                setTotalAmount(total);
                setCurrencyData(response.data.shopping_cart_data);
                setCouponCode(response.data.shopping_cart_data.coupon_code);
                setDiscountedAmount(response.data.shopping_cart_data.total_cart_discounted_value);
                setIsCouponApplied(!!response.data.shopping_cart_data.coupon_code); // Update based on fetched data
                setSelectCoupon(response.data.shopping_cart_data.active_coupon_count); // Update based on fetched data
           //coupon id
           setCouponOwnerId(response.data.shopping_cart_data.coupon_owner_id);
            } 
          
            else {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
        } catch (error) {
            console.error('Failed to fetch cart details:', error);
            setError(error.message);
        }
    };

    useEffect(() => {
        fetchCartDetails();
    }, []);

    const handleCouponCodeChange = (e) => {
        setCouponCode(e.target.value);
        setIsCouponApplied(false); // Reset this state when the code is changed
    };

    const handleApplyCoupon = async () => {
        try {
            const response = await api.post('/cart/apply_coupon_discount', {
                api_token: localStorage.getItem('apiToken'),
                coupon_code: couponCode,
                order_num: localStorage.getItem('order_num'),
            });
            if (response.data.status === 401) {

                console.log('401');
                logout();
                navigate('/home');
            }
           else if (response.data.status === 200 && response.data.data) {
                setIsCouponApplied(true); // Set as applied only on successful API call
                await fetchCartDetails();
            }
           
             else {
                throw new Error(response.data.message || 'Failed to apply coupon');
            }
        } catch (error) {
            console.error('Failed to apply coupon:', error);
            setError('Failed to apply coupon. Please try again.');
        }
    };

    const handleRemoveCoupon = async () => {
        try {
            const response = await api.post('/cart/apply_coupon_discount', {
                api_token: localStorage.getItem('apiToken'),
                coupon_code: '',
                order_num: localStorage.getItem('order_num'),
            });
            if (response.data.status === 401) {

                console.log('401');
                logout();
                navigate('/home');
            }
           else if (response.data.status === 200 && response.data.data) {
                setCouponCode('');
                setIsCouponApplied(false); // Reset the applied state
                await fetchCartDetails();
            }
           
                else {
                throw new Error(response.data.message || 'Failed to remove coupon');
            }
        } catch (error) {
            console.error('Failed to remove coupon:', error);
            setError('Failed to remove coupon. Please try again.');
        }
    };

    if (error) {
        return <div>Error: {error}</div>;
    }
    const handleRemoveItem = async (itemSUI, cartID) => {
        try {
            const order_num = localStorage.getItem('order_num');
            const cart_id = localStorage.getItem('cart_id');

            const response = await api.post('/cart/remove_item_from_cart', {
                api_token: apiToken,
                cart_id: cart_id,
                order_num,
                sui: itemSUI,
            });
            if (response.data.status === 401) {

                console.log('401');
                logout();
                navigate('/home');
            }
            else if (response.data.status === 200) {
                await fetchCartDetails();
                // const updatedCartData = cartData.filter((item) => item.SUI !== itemSUI);
                // setCartData(updatedCartData);
                removeItemFromCart();
                // const newTotal = updatedCartData.reduce((acc, item) => acc + parseFloat(item.total), 0);
                // setTotalAmount(newTotal);
            }
           
            else {
                throw new Error(`Failed to remove item! Status: ${response.status}`);
            }
        } catch (error) {
            console.error('Error removing item:', error);
            setError('Failed to remove item. Please try again.');
        }
    };
   
    const handleNavigateCouponList = (couponID, selectCoupon) => {
        console.log("Navigating with:", { couponID, selectCoupon });
        navigate('/coupon-list', {
          state: { couponID, selectCoupon }
        });
      };
      const formatAmount = (amount) => {
        return `${currencyData.currency_symbol}${amount}`;
    };
    return (
        <div className="main-content">
            <div className="container order-details-pg">
                <h2>Cart Details</h2>
                <div className="new-orderrow">
                    <div className="order-details">
                        <div className="store-order">
                            {cartCount === 0 ? (
                                <p>No items in the cart</p>
                            ) : (
                                Object.entries(
                                    cartData.reduce((acc, item) => {
                                        if (!acc[item.store_name]) {
                                            acc[item.store_name] = [];
                                        }
                                        acc[item.store_name].push(item);
                                        return acc;
                                    }, {})
                                ).map(([storeName, products]) => (
                                    <div key={storeName} className="store-section">
                                        <div className="store-name">
                                            <h4>Store: {storeName}</h4>
                                        </div>
                                        {products.map((item) => (
                                            <div key={item.SUI} className="product-row">
                                                <Link to={`/product/${item.SUI}`}>
                                                    <div className="img-block">
                                                        <img src={item.product_image} alt={item.product_title} />
                                                    </div>
                                                </Link>
                                                <div className="product-details">
                                                    <div className="pro-name">
                                                        <h4>
                                                            <Link to={`/product/${item.SUI}`}>{item.product_title}</Link>
                                                        </h4>
                                                    </div>
                                                    <div className="color">
                                                        <span>Colour:</span> {item.color_name}
                                                    </div>
                                                    <div className="size">
                                                        <span>Size:</span> {item.size_name}
                                                    </div>
                                                    <div className="size">
                                                        <span>Qty:</span> {item.quantity}
                                                    </div>
                                                    <div className="remove-item">
                                                        <Link
                                                            to="#"
                                                            className="remove"
                                                            onClick={() => handleRemoveItem(item.SUI, item.cart_id)}
                                                        >
                                                            <img src="/images/remove.svg" alt="Remove item" />
                                                        </Link>
                                                    </div>
                                                    <div className="description content">
                                                        <div className="price-value">
                                                            {item.original_price !== item.final_price ? (
                                                                <>
                                                                    <span className="strike_price">
                                                                        {`${currencyData.currency_symbol}${item.original_price}`}
                                                                    </span>
                                                                    <span className="final_price">
                                                                        {`${currencyData.currency_symbol}${item.final_price}`}
                                                                    </span>
                                                                    <span className="discount">
                                                                        {`${item.platform_discount}% off`}
                                                                    </span>
                                                                </>
                                                            ) : (
                                                                <span className="final_price">
                                                                    {`${currencyData.currency_symbol}${item.final_price}`}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ))
                            )}
                        </div>
                    </div>
                    {cartCount > 0 && (
    <div className="items-details ord-details">
        <div className="order-coupon">
            {selectCoupon > 0 && (
                <div className="h4">
                    <span>Coupon Code</span>
                    <button onClick={() => handleNavigateCouponList(couponOwnerId, selectCoupon)}>
                        Select Coupon
                    </button>
                </div>
            )}
        </div>

        <div className="apply">
            {isCouponApplied ? (
                <div className="coupon-code-applied">
                    <span>{couponCode}</span>
                    <button type="button" onClick={handleRemoveCoupon}>Remove</button>
                </div>
            ) : (
                <div className="coupon-code-not-applied">
                    <input
                        type="text"
                        placeholder="Enter Coupon Code"
                        value={couponCode}
                        onChange={handleCouponCodeChange}
                        disabled={selectCoupon <= 0}
                    />
                    <button
                        type="button"
                        onClick={handleApplyCoupon}
                        disabled={selectCoupon <= 0}
                    >
                        Apply Coupon
                    </button>
                </div>
            )}
        </div>

        <div className="gift">
            {currencyData.coupon_discount_amount > 0 && (
                <table className="total">
                    <tbody>
                        <tr>
                            <td>Subtotal</td>
                            <td>{formatAmount(currencyData.total_cart_before_coupon_discounted)}</td>
                        </tr>
                        <tr>
                            <td>Coupon Applied ({couponCode}):</td>
                            <td>-{formatAmount(currencyData.coupon_discount_amount)}</td>
                        </tr>
                    </tbody>
                </table>
            )}
        </div>

        <div className="total-paid">
            <div className="paid-text">
                <h4>Total <span>(Excluding Shipping charges)</span></h4>
                <span className="price">
                    {isCouponApplied ? (
                        `${currencyData.currency_symbol}${discountedAmount}`
                    ) : (
                        `${currencyData.currency_symbol}${totalAmount}`
                    )}
                </span>
            </div>
        </div>

        <div className="checkout">
            <button
                type="button"
                className="primary-btn"
                onClick={() => window.location.href = '/checkout'}
            >
                Checkout
            </button>
        </div>
    </div>
)}

                </div>
            </div>
        </div>
    );
};

export default Cart;
