import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton'; // Import the skeleton loader
import 'react-loading-skeleton/dist/skeleton.css'; // Import the skeleton CSS
import { AuthProvider, useAuth } from './context/AuthContext';
import { CartProvider } from './context/CartContext';
import { CurrencyProvider } from './context/CurrencyContext';
import { CheckoutProvider } from './context/CheckoutContext';
import ScrollToTop from './components/scroll/ScrollToTop';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import AppRoutes from './routes/Routes';
import { ToastNotification } from './components/toastNotification/ToastNotification';
import './App.css';

const AppContent = () => {
    // Cache Versioning
    const cacheVersion = '1.0.9';
    const { loading, apiToken, error } = useAuth(); // Use the useAuth hook to access state and actions

    useEffect(() => {
        // Cache versioning logic
        const storedVersion = localStorage.getItem('appVersion');
        if (storedVersion !== cacheVersion) {
            localStorage.clear(); // Clear cache if app version is different
            localStorage.setItem('appVersion', cacheVersion);
            window.location.reload();
        }
    }, [cacheVersion]);
 if (loading || error) {
    return null; // Hide everything during loading or when there's an error
}
    if (!apiToken) {
        return <div>No valid token found. Please log in.</div>;
    }

    // Render the app content when the token is valid and no errors are present
    return (
        <>
            <Header />
            <main className="main-content">
                <ScrollToTop />
                <AppRoutes />
            </main>
            <Footer />
        </>
    );
};

const App = () => {
    return (
        <AuthProvider>
            <CurrencyProvider>
                <CartProvider>
                    <CheckoutProvider>
                        <Router>
                            <div className="app-container">
                            <ToastNotification /> {/* This must be included in your app */}
                                <AppContent />
                            </div>
                        </Router>
                    </CheckoutProvider>
                </CartProvider>
            </CurrencyProvider>
        </AuthProvider>
    );
};

export default App;
