import React from 'react';
import LatestArrivalsMain from '../LatestArrivalsMain';

const WeddingStoreMain = () => {
    return (
        <div>
            <LatestArrivalsMain
                tagId={95}
                pageTitle="Wedding Store"
                apiEndpoint="/tags/get_list_for_tag" // If you need to use a different API endpoint
            />
        </div>
    );
};

export default WeddingStoreMain;
