import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext'; 
import api from '../../api';
import ProfileFavorites from './ProfileFavorites';
import ProfileGifts from './ProfileGifts';

const Profile = () => {
    const { logout } = useAuth();

    const navigate = useNavigate();
    const [profileData, setProfileData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [activeTab, setActiveTab] = useState('favorites'); // Use 'favorites' and 'gifts' for tab keys
    const apiToken = localStorage.getItem('apiToken');

    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                const response = await api.get('/user/get_user_profile', {
                    params: {
                        api_token: apiToken,
                        user_uuid: '',
                        segment_id: 1,
                        menu_order: 6,
                    },
                });
                 if (response.status === 401) {
                    logout();
                    navigate('/home');
                }
               else if (response.status !== 200) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                setProfileData(response.data.user_data || {});
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchUserProfile();
    }, [apiToken]);

    useEffect(() => {
        if (
            !localStorage.getItem('userUid') &&
            !localStorage.getItem('LoggedIn') &&
            localStorage.getItem('userRole') === 'guest'
        ) {
            navigate('/login');
        }
    }, [navigate]);

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>Error: {error}</p>;
    }

    return (
        <div className="main-content not-home">
            <div className="profile-share">
                <div className="profile-option">
                    <div className="container">
                        <div className="share-links">
                            <ul>
                                <li>
                                    <Link to="#"><span className="share-icon"></span>Share Profile</Link>
                                </li>
                                <li>
                                    <Link to="#"><span className="share-icon"></span>Referral Link</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="profile-details">
                            <div className="avtar-img">
                                <img src={profileData?.image_url} alt="Avatar" />
                                <h5>{profileData?.profile_name}</h5>
                                <span>{profileData?.profile_title}</span>
                            </div>
                            <div className="profile-right">
                                <ul className="profile-details">
                                    <li>
                                        <h4>{profileData?.num_followers}</h4>
                                        <span>Followers</span>
                                    </li>
                                    <li>
                                        <h4>{profileData?.num_following}</h4>
                                        <span>Following</span>
                                    </li>
                                    <li>
                                        <h4>{profileData?.coupon_count}</h4>
                                        <span>Coupons</span>
                                    </li>
                                    <li>
                                        <h4>
                                            {profileData?.total_credits > 0 ? `${profileData?.currency_data[1]}${profileData?.total_credits}` : 0}
                                        </h4>

                                        <span>Credits</span>
                                    </li>
                                </ul>
                                <ul className="acc-btn">
                                    <li>
                                        <Link to="/my-account"><span><img src="/images/acc-info.png" alt="Account Info" /></span> Account</Link>
                                    </li>
                                    <li>
                                        <Link to="/my-order"><span><img src="/images/acc-info.png" alt="Order Info" /></span> Order</Link>
                                    </li>
                                    <li>
                                        <Link to="/my-gift"><span><img src="/images/acc-info.png" alt="Gift Credits" /></span> Gift Credits</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="tabs shop-tab">
                <div className="container">
                    <ul id="tabs-nav">
                        <li className={activeTab === 'favorites' ? 'active' : ''}>
                            <Link to="#" onClick={() => handleTabClick('favorites')}><span></span> Favourites</Link>
                        </li>
                        <li className={activeTab === 'gifts' ? 'active' : ''}>
                            <Link to="#" onClick={() => handleTabClick('gifts')}><span></span> Gift-List</Link>
                        </li>
                    </ul>
                </div>
                <div className='container'>

                    <div id="tabs-content">
                        {activeTab === 'favorites' && <ProfileFavorites apiToken={apiToken} />}
                        {activeTab === 'gifts' && <ProfileGifts apiToken={apiToken} />}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Profile;
