import React, { useState, useEffect, useCallback } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Link, useNavigate, useParams } from 'react-router-dom';

import api from '../../api';
import { useAuth } from '../../context/AuthContext'; 
import StoreTabFilter from './StoreTabFilter';
import StoreFilterProductListing from './StoreFilterProductListing';
import StoreFilterProducts from './StoreFilterProducts';
import _ from 'lodash';  // Import lodash for debounce

const StoreFilter = ({
    storeUuid,
    genderList,
    loading,
    onTabSelection,
}) => {
    const { logout } = useAuth();
const navigate = useNavigate();
    const [tab, setTab] = useState('all');
    const [showAdditionalFilters, setShowAdditionalFilters] = useState(false);
    const [activeTab, setActiveTab] = useState('');
    const [filterOptions, setFilterOptions] = useState({
        sizeOptions: [],
        priceOptions: [],
        colorOptions: [],
        sortOptions: [
            { value: 'ASC', label: 'Price Low To High' },
            { value: 'DESC', label: 'Price High To Low' },
        ]
    });

    const [sortOption, setSortOption] = useState('');
    const [sizeOption, setSizeOption] = useState('');
    const [priceOption, setPriceOption] = useState('');
    const [colorOption, setColorOption] = useState('');
    const [segmentId, setSegmentId] = useState('');
    const [currencyCode, setcurrencyCode] = useState('');
    const [genderId, setGenderId] = useState('');
    const [categoryList, setCategoryList] = useState('');
    const [productData, setProductData] = useState(null); // State to store response data
    const [isLoadingData, setIsLoadingData] = useState(false); // State to track loading status
    const [apiCallType, setApiCallType] = useState(''); // Track the type of API call
    const [activeCategoryId, setActiveCategoryId] = useState(null); // New state to track the active category
    const hasWomen = genderList?.Women && genderList.Women.length > 0;
    const hasMen = genderList?.Men && genderList.Men.length > 0;
    const hasUnisex = genderList?.Unisex && genderList.Unisex.length > 0;

    useEffect(() => {
        if (hasWomen) {
            setActiveTab('tab1');
        } else if (hasMen) {
            setActiveTab('tab2');
        } else if (hasUnisex) {
            setActiveTab('tab3');
        }
    }, [hasWomen, hasMen, hasUnisex]);

    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
        setShowAdditionalFilters(false); // Hide filters when switching tabs
        onTabSelection(tabId);
    };

    // Separate function for item clicks
    const fetchProductDataForItem = async (store_uuid, segment_id, gender_id, category_list) => {
        setIsLoadingData(true);
        try {
            const api_token = localStorage.getItem('apiToken');
            const currencyCode = localStorage.getItem('currencyCode');

            const response = await api.get('/filters/get_skus_for_filter', {
                params: {
                    api_token,
                    page: 0,
                    store_uuid,
                    segment_id,
                    gender_id,
                    category_list,
                    currency_code: currencyCode,
                    sort_order: '',
                    size_list: '',
                    color_list: '',
                    price_min: '',
                    price_max: '',
                    sub_category_list: ''
                },
            });

            if (response.status === 200) {
                setProductData(response.data);
                const PriceListResponse_price_band = response.data.price_band;
                const colorListResponse = await api.get('/filters/get_colors_list', {
                    params: {
                        api_token,
                        gender_id,
                        segment_id,
                        categories_list: category_list,
                        store_uuid,
                        price_min: '',
                        price_max: '',
                        sub_categories_list: '',
                    },
                });
                const colorListResponse_colors_data = colorListResponse.data.colors_data;
                const sizeListResponse = await api.get('/filters/get_sizes_list', {
                    params: {
                        api_token,
                        gender_id,
                        segment_id,
                        categories_list: category_list,
                        store_uuid,
                        price_min: '',
                        price_max: '',
                        sub_categories_list: '',
                    },
                });
                const sizeListResponse_sizes_data = sizeListResponse.data.sizes_data;

                setFilterOptions({
                    ...filterOptions,
                    sizeOptions: sizeListResponse_sizes_data,
                    priceOptions: PriceListResponse_price_band,
                    colorOptions: colorListResponse_colors_data,
                });

                setShowAdditionalFilters(true); // Show filters after fetching data
                setSegmentId(segment_id);
                setGenderId(gender_id);
                setCategoryList(category_list);
                setcurrencyCode(currencyCode);
            } 
            else if (response.status === 401) {
                logout();
                navigate('/home')

            }
            else {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setIsLoadingData(false);
        }
    };

    // Separate function for filter changes
    const fetchFilteredData = async () => {
        setIsLoadingData(true);
        const formattedSize = sizeOption && sizeOption.length > 0 ? sizeOption.join(',') : '';
        const formattedColor = colorOption && colorOption.length > 0 ? colorOption.join(',') : '';

        try {
            const api_token = localStorage.getItem('apiToken');
            const currencyCode = localStorage.getItem('currencyCode');

            const response = await api.get('/filters/get_skus_for_filter', {
                params: {
                    api_token,
                    store_uuid: storeUuid,
                    segment_id: segmentId,
                    gender_id: genderId,
                    category_list: categoryList,
                    sub_category_list: '',
                    size_list: formattedSize,
                    color_list: formattedColor,
                    price_min: priceOption.split('-')[0] || '', // Assuming priceOption is in format "min-max"
                    price_max: priceOption.split('-')[1] || '',
                    sort_order: sortOption,
                    page: 0,
                    currency_code: currencyCode,
                },
            });

            if (response.status === 200) {
                setProductData(response.data);
                const PriceListResponse_price_band = response.data.price_band;
                const colorListResponse = await api.get('/filters/get_colors_list', {
                    params: {
                        api_token,
                        gender_id:genderId,
                        segment_id:segmentId,
                        categories_list: categoryList,
                        store_uuid:storeUuid,
                        price_min: priceOption.split('-')[0] || '', // Assuming priceOption is in format "min-max"
                        price_max: priceOption.split('-')[1] || '',
                        sub_categories_list: '',
                    },
                });
                const colorListResponse_colors_data = colorListResponse.data.colors_data;
                const sizeListResponse = await api.get('/filters/get_sizes_list', {
                    params: {
                        api_token,
                        gender_id:genderId,
                        segment_id:segmentId,
                        categories_list: categoryList,
                        store_uuid:storeUuid,
                        price_min: priceOption.split('-')[0] || '', // Assuming priceOption is in format "min-max"
                        price_max: priceOption.split('-')[1] || '',
                        sub_categories_list: '',
                    },
                });
                const sizeListResponse_sizes_data = sizeListResponse.data.sizes_data;

                setFilterOptions({
                    ...filterOptions,
                    sizeOptions: sizeListResponse_sizes_data,
                    priceOptions: PriceListResponse_price_band,
                    colorOptions: colorListResponse_colors_data,
                });

                setShowAdditionalFilters(true); // Show filters after fetching data
                setSegmentId(segmentId);
                setGenderId(genderId);
                setCategoryList(categoryList);
                setcurrencyCode(currencyCode);
            } 
            else if (response.status === 401) {
                logout();
                navigate('/home')

            }
            else {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
        } catch (error) {
            console.error('Error fetching filtered data:', error);
        } finally {
            setIsLoadingData(false);
        }
    };

    const handleItemClick = (store_uuid, segment_id, gender_id, category_list) => {
        setActiveCategoryId(category_list); // Set active category ID
        setApiCallType('item'); // Set API call type for item click
        fetchProductDataForItem(store_uuid, segment_id, gender_id, category_list);
    };

    const handleFilterChange = useCallback(() => {
        setApiCallType('filter'); // Set API call type for filter change
        fetchFilteredData();
    }, [sortOption, sizeOption, priceOption, colorOption]);

    const debouncedHandleFilterChange = useCallback(
        _.debounce(handleFilterChange, 300),
        [sortOption, sizeOption, priceOption, colorOption]
    );

    useEffect(() => {
        if (segmentId && genderId && categoryList) {
            debouncedHandleFilterChange();
        }
    }, [sortOption, sizeOption, priceOption, colorOption, debouncedHandleFilterChange]);

    const handleSortChange = (e) => {
        setSortOption(e);
    };

    const handleSizeChange = (e) => {
        console.log(e,'handleSizeChange');
        setSizeOption(e); // Assuming e.target.value for single size selection
    };

    const handlePriceChange = (e) => {
        console.log(e,'price_selected');
        // setPriceOption(e.target.value);
        setPriceOption(e);
    };

    const handleColorChange = (e) => {
        setColorOption(e); // Assuming e.target.value for single color selection
    };

    const handleClearFilters = () => {
        console.log('handleClearFilters function');
        setSizeOption('');
        setSortOption('');
        setPriceOption('');
        setColorOption('');
        setShowAdditionalFilters(false);
        setSegmentId('');
        setGenderId('');
        setCategoryList('');
        setProductData(null);
    };

    const handlePriceClearFilter = () => {
        console.log('price clear filter handlePriceClearFilter');
        setPriceOption('');
        setShowAdditionalFilters(true);
       
    };
    const handleSizeClearFilter = () => {
        console.log('Size clear filter handleSizeClearFilter');
        setSizeOption([]);
        setShowAdditionalFilters(true);
       
    };
    const handleColorClearFilter = () => {
        console.log('Color clear filter handleColorClearFilter');
        setColorOption([]);
        setShowAdditionalFilters(true);
       
    };
    return (
        <div className="store-filter">
            <div className="container">
                {loading ? (
                    <>
                        <Skeleton height={30} width={100} />
                        <Skeleton count={3} height={100} />
                    </>
                ) : (
                    <>
                        <div className="tabs">
                            <ul id="tabs-nav">
                                {hasWomen && (
                                    <li className={activeTab === 'tab1' ? 'active' : ''}>
                                        <a href="#tab1" onClick={() => handleTabClick('tab1')}>Women</a>
                                    </li>
                                )}
                                {hasMen && (
                                    <li className={activeTab === 'tab2' ? 'active' : ''}>
                                        <a href="#tab2" onClick={() => handleTabClick('tab2')}>Men</a>
                                    </li>
                                )}
                                {hasUnisex && (
                                    <li className={activeTab === 'tab3' ? 'active' : ''}>
                                        <a href="#tab3" onClick={() => handleTabClick('tab3')}>Unisex</a>
                                    </li>
                                )}
                            </ul>
                            <div id="tabs-content">
                                {activeTab === 'tab1' && hasWomen && (
                                    <div id="tab1" className="tab-content">
                                        <ul className="women-list">
                                            {genderList.Women.map((item) => (
                                                <li key={item.category_id}>
                                                    <a href="#" onClick={(e) => {
                                                        e.preventDefault();
                                                        handleItemClick(storeUuid, item.segment_id, item.gender_id, item.category_id);
                                                    }}
                                                        className={`category-item ${activeCategoryId === item.category_id ? 'active_category' : ''}`} // Apply class conditionally
                                                    >

                                                        <img
                                                            src={item.image_url || 'https://via.placeholder.com/150'}
                                                            alt={item.name}
                                                        />
                                                        <span>{item.name}</span>
                                                    </a>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                                {activeTab === 'tab2' && hasMen && (
                                    <div id="tab2" className="tab-content">
                                        <ul className="men-list">
                                            {genderList.Men.map((item) => (
                                                <li key={item.category_id}>
                                                    <a href="#" onClick={(e) => {
                                                        e.preventDefault();
                                                        handleItemClick(storeUuid, item.segment_id, item.gender_id, item.category_id);
                                                    }}
                                                        className={`category-item ${activeCategoryId === item.category_id ? 'active_category' : ''}`} // Apply class conditionally
                                                    >

                                                        <img
                                                            src={item.image_url || 'https://via.placeholder.com/150'}
                                                            alt={item.name}
                                                        />
                                                        <span>{item.name}</span>
                                                    </a>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                                {activeTab === 'tab3' && hasUnisex && (
                                    <div id="tab3" className="tab-content">
                                        <ul className="unisex-list">
                                            {genderList.Unisex.map((item) => (
                                                <li key={item.category_id}>
                                                    <a href="#" onClick={(e) => {
                                                        e.preventDefault();
                                                        handleItemClick(storeUuid, item.segment_id, item.gender_id, item.category_id);
                                                    }}
                                                        className={`category-item ${activeCategoryId == item.category_id ? 'active_category' : ''}`} // Apply class conditionally
                                                    >

                                                        <img
                                                            src={item.image_url || 'https://via.placeholder.com/150'}
                                                            alt={item.name}
                                                        />
                                                        <span>{item.name}</span>
                                                    </a>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>

                        <StoreTabFilter
                            sortOptions={filterOptions.sortOptions}
                            sizeOptions={filterOptions.sizeOptions}
                            priceOptions={filterOptions.priceOptions}
                            colorOptions={filterOptions.colorOptions}
                            onSortChange={handleSortChange}
                            onSizeChange={handleSizeChange}
                            onPriceChange={handlePriceChange}
                            onColorChange={handleColorChange}
                            onClearFilters={handleClearFilters}
                            onPriceClearFilter={handlePriceClearFilter}
                            onSizeClearFilter={handleSizeClearFilter}
                            onColorClearFilter={handleColorClearFilter}
                            sortOption={sortOption}
                            sizeOption={sizeOption}
                            priceOption={priceOption}
                            colorOption={colorOption}
                            showAdditionalFilters={showAdditionalFilters}
                        />
                        {/* Conditionally render components based on productData */}
                        {productData ? (
                            <StoreFilterProductListing
                                storeUuid={storeUuid}
                                sortOption={sortOption}
                                sizeOption={sizeOption}
                                priceOption={priceOption}
                                colorOption={colorOption}
                                segmentId={segmentId}
                                genderId={genderId}
                                categoryList={categoryList}
                                productData={productData}
                                onClearFilters={handleClearFilters}
                                loading={loading}
                            />
                        ) : (
                            <StoreFilterProducts
                                storeUuid={storeUuid}
                                loading={loading}
                                onClearFilters={handleClearFilters}
                                sortOption={sortOption}
                            />
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default StoreFilter;
