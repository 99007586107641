// PrivacyPolicy.js
import React from 'react';
// import './PrivacyPolicy.css'; // Optional: Create this CSS file if you need to style the component

const PrivacyPolicy = () => {
    return (<div className="main-content not-home">
        <div className="container">
            <div className="delete-acc">
                <h2>Introduction</h2>
                <p>Fractal Systems LLC, a company registered in Delaware with a registered office at 1209 Orange
                    Street, Wilmington, DE 19801 operates the platform with brand name <strong>Seashels</strong>
                    (hereinafter referred as “<strong>Seashels</strong>”, “<strong>We</strong>”,
                    “<strong>Us</strong>” or “<strong>Our</strong>”).</p>

                <p>We respect Your privacy while you access ‘<strong>Seashels’ (“Application”)</strong>/
                    www.seashels.com (“<strong>Website</strong>”) and other associated applications, devices,
                    products, online marketplace and services managed by Seashels that reference to this Privacy
                    Policy (collectively referred to as the “Platform”). For the purposes of this privacy policy the
                    term “User(s)” and wherever the context so requires “You”, “Your”, “Yourself” shall mean any
                    natural or legal person who accesses or uses the Platform and in the event that a natural person
                    is representing a business entity, reference to such terms shall include a reference to such
                    business entity as well</p>

                <p>We, at Seashels, aim to adhere to the ethical standards pertaining to collection, usage and
                    safeguarding of the information provided by you during the use of the Application and/or the
                    Website.</p>

                <p>Seashels has adopted this privacy policy (“<strong>Privacy Policy</strong>”) to inform You of:
                    (i) the information We collect from You through the Platform; (ii) the manner of collection and
                    processing of such information; (iii) use of such information; and (iv) the steps We take to
                    protect it. Capitalized terms not defined herein shall have the meaning ascribed to them in the
                    Merchants’ Agreement and the Buyers’ Agreement. By using the Platform, you unconditionally agree
                    to the terms and conditions of this Privacy Policy and consent to collecting, usage, storage,
                    processing, handling, transferring and sharing of the information submitted by You in accordance
                    with the terms contained hereunder. Please read the Privacy Policy before using the Platform and
                    if You do not agree with the terms and conditions of this Privacy Policy, please do not proceed
                    further to use this Platform.</p>

                <h4>USER INFORMATION</h4>
                <p>The Platform collects certain information and data when you register via the Application or the
                    Website. There are two types of information being collected: (i) User Provided Information; (ii)
                    Automatically Collected Information during the usage of the Service.</p>

                <h3>User Provided Information and Access</h3>

                <ol>
                    <li>Seashels collects Your Information when You successfully submit information while creating
                        Your account and/or while confirming Your order on the Platform. While doing so, we may
                        collect data that can be used to uniquely identify or contact a person and/or the business
                        entity that a person represents and shall include, but not be limited to, information
                        regarding Your name, name of the business entity that You represent, Your and/or Your
                        customer’s address and telephone number, Your and/or Your customer’s e-mail address,
                        information provided by You regarding Your social networking platform and such other
                        information for the purposes of identification and verification. We may also ask you to
                        share the OTP sent via SMS or a password or to verify a link sent on your e-mail address.
                        While you are using our Platform, we record your responses and activity data, including but
                        not limited to, textual and voice responses and session details. We do not directly process
                        any payments and do not store your debit/credit card information. Secured socket layer
                        technology is used for processing payment transactions with third party service provider
                        partners. When You submit User Information on the Platform including any information of Your
                        customers, it shall be deemed that You have granted Us the right to collect, store, process,
                        handle and use such User Information, in accordance with this Privacy Policy (as amended
                        from time to time) and You confirm you have adequate and legally appropriate consents from
                        Your customers to share the same. To facilitate refunds in case of returns, you may also be
                        asked to provide bank account information.</li>
                    <li>While using the Platform, we may ask permission to access the microphone and the camera for
                        providing better services and products. We may ask for permission to access your data
                        storage. However, the data storage access shall be limited to the access to store the data
                        downloaded or saved during the usage of the Platform or the products directly in the User’s
                        device for ensuring easy storage and access to the User. We shall not utilize this
                        permission to access to any other data on the User’s device, except as expressly mentioned
                        in this Privacy Policy.</li>
                    <li>We use a third party payment platform and payment aggregator services to bill you for
                        products and services. We may use and share the User Information with reliable and reputed
                        third-party payment gateway to whom we are associated in order to ensure swift and
                        comfortable payment mechanism for the User. The third party service providers shall provide
                        the Company the phone number of the User utilized while completing the payment transaction.
                    </li>
                    <li>After the User is directed to the payment mechanism and the payment gateway, the Company is
                        not liable for any data stored, used or accessed by such a third party service provider and
                        the User Information is no longer governed by this Policy or our Website’s Terms of Service.
                        The same shall be subject to the terms and conditions and privacy policy of the third party
                        service provider. For these third party service providers, we recommend that you read their
                        privacy policies in order to understand the manner in which your personal information and
                        your credit/debit card details will be handled by these providers.</li>
                    <li>The said information collected from the Users could be categorized as “Personal
                        Information”, “Sensitive Personal Information” or other information. The Sensitive Personal
                        Information collected would be governed in accordance with the Information Technology
                        (Reasonable security practices and procedures and sensitive personal data or information)
                        Rules, 2011 (the “<strong>IT Rules</strong>”). The Personal Information, Sensitive Personal
                        Information or other information shall collectively be referred to as “<strong>User
                            Information</strong>” in this Policy.</li>
                    <li>We may use the User Information to contact you from time to time, to provide you with the
                        services we offer through the Platform, important information and circulate marketing and
                        promotional material in relation thereto through SMS, calls, and email. By using this
                        Platform, you are also opting-in to receive such information and promotional content via
                        WhatsApp. In case, you do not wish to receive any communications via WhatsApp, please give
                        us a missed call on (+91) 74985 44409 to opt-out of this facility. We may ask you for more
                        information for identification purposes, if required (personal information). The User shall
                        be permitted to access the Website or Application via any device provided that the log
                        in/access credentials match with the User credentials. In the event the User uses a new
                        device to access or log-in to the Website or Application, the User shall be required to
                        grant access for the new device, as may be required.</li>
                    <li>You may visit the Platform and browse the Platform without having to provide User
                        Information. We will, at all times, provide the option to you to not provide the Personal
                        Information or Sensitive Personal Information, which we seek from you. Where possible, we
                        indicate which fields are mandatory and which fields are optional to be filled on the
                        Platform. You always have the option to not provide information by choosing not to submit
                        particular information or feature on the Platform. In such event, however, the Company fully
                        reserves the right not to allow further usage of the Platform or provide any
                        services/products thereunder to you.</li>
                    <li>By using this Platform, you consent to the collection, storage, use and transfer of the User
                        Information (including personal information and sensitive personal data, including my
                        payment details) that you provide in connection with any of the services that We offer
                        through our Platform in accordance with this Policy and You consent to the collection of any
                        changes or updates that you may provide to the User Information.</li>
                    <li>Seashels collects only such User Information that Seashels believes to be relevant for the
                        purpose of identification and verification and is required to understand You or Your
                        interests. It is clarified that We shall not be liable, for any reason whatsoever, for the
                        authenticity of any User Information provided by You to Us. You hereby confirm that the User
                        Information provided by You is and shall continue to be valid, true and accurate to the best
                        of Your knowledge.</li>
                    <li>We may also ask you to provide information regarding your location, age, gender and contacts
                        that shall include, but not limited to, their name and phone number. This information will
                        not be sold to, or shared with, any unaffiliated third party.</li>
                    <li>Any portion of the Information containing personal data relating to minors or a person of
                        unsound mind provided by you shall be deemed to be given with the consent of the legal
                        guardian. Such consent is deemed to be provided by your registration with us.</li>
                </ol>
                <h4>Automatically Collected Information</h4>
                <p>When you visit the Platform, we may collect certain information about or on your device such as
                    your internet protocol address, operating system, browser type, and internet service provider.
                    This type of information does not identify you personally during Your visit to the Platform. We
                    can identify You only after You submit User Information at the time of registering Yourself with
                    the Platform.</p>
                <h3>COLLECTION OF INFORMATION</h3>
                <p>The User Information collected by the Company will be deleted as per the Data Retention Guideline
                    identified below, the Company may, at its option, not provide any services for which the User
                    Information was sought and the User shall not claim deficiency of services on the basis of such
                    non provision of goods and services.</p>
                <p>To enhance Your use of the Platform, certain information may be collected each time You visit the
                    Platform which are saved in server logs. It is clarified that these statistics help Us in
                    improving the efficiency of the Platform by giving Us information relating to Your use of the
                    Platform. Such information may include details of the server from where the Platform is being
                    accessed, the browser and operating system used to browse the Platform, details of Your last
                    visit to the Platform, including time, date and the duration of Your session on the Platform.
                    This information is used by Seashels to understand the number of users visiting the Platform and
                    gather broad demographic information for aggregate use of the Platform. While collecting such
                    information, Your anonymity shall be maintained at all times and at no time can We identify You
                    personally, unless You submit the Information on the Platform or through the e-mail feature.
                    Seashels reserves the right to share such general information to any person on its discretion.
                </p>
                <p>You acknowledge that apart from Your User Information, if You upload or exchange any data,
                    content, information, pictorial representations and/or images including post any comments on the
                    Platform (collectively referred to as the “Content”), such Content may contain information
                    including User Information and the same may be available to the other Users of the Platform.
                    Seashels will not be liable for the disclosure and dissemination of such User Information on the
                    Platform.</p>
                <h3>COOKIES</h3>
                <p>Like most other Platforms, We use data collection devices known as cookies to collect and store
                    information of Users visiting the Platform. A cookie is a small amount of data that is sent to a
                    User’s browser from a web server/mobile application and is eventually stored on a User’s
                    computer hard drive/mobile device. Cookies are a reliable mechanism for Platforms to remember
                    the activities of the User on the Platform and helps in improving Your experience on the
                    Platform. This anonymous information is maintained distinctly and is not linked to the User
                    Information You submit to Seashels. The option of accepting cookies is up to You, however
                    certain features of the Platform including Content and the forms may not be accessible without
                    accepting cookies.</p>
                <p>To browse anonymously, You may set your browser to disable cookies or delete cookies. Most
                    cookies are session cookies that are automatically deleted from Your hard drive when You close
                    the browser. Additionally, You may encounter cookies or other similar devices on certain pages
                    of the Platform that are placed by third parties. We do not control the use of cookies by third
                    parties and shall not be liable for any reason whatsoever for these third party cookies.</p>
                <h3>USE OF THE USER INFORMATION</h3>
                <p>We collect the User Information provided by You for the reasons including but not limited to the
                    following:</p>
                <ol>
                    <li>verifying Your identity;</li>
                    <li>facilitating Your use of the Platform;</li>
                    <li>facilitating successful purchase of Products from the Platform;</li>
                    <li>responding to Your queries or complaints or to fulfil Your requests for information about
                        the services and solutions that We offer and the process relating to the same;</li>
                    <li>providing You with information about Seashels and to send You information, materials, and
                        offers from Seashels;</li>
                    <li>sending You important information regarding the Platform;</li>
                    <li>sending You surveys and marketing communications that Seashels believes may be of interest
                        to You;</li>
                    <li>personalizing Your experience on the Platform by presenting offers tailored to You;</li>
                    <li>helping You address trouble- shooting problems;</li>
                    <li>providing You with related customer service;</li>
                    <li>conducting internal reviews and data analysis for the Platform (e.g., to determine the
                        number of visitors to specific pages within the Platform);</li>
                    <li>sharing such information with third parties that We consider may have goods or services that
                        are of interest to You;</li>
                    <li>improving the Content on the Platform and to improve our features and provide seamless
                        service;</li>
                    <li>protecting the integrity of the Platform; and</li>
                    <li>To protect against imminent harm to the rights, property or safety of the Platform, the
                        Company, its Users or the public as required or as may be permissible under the applicable
                        law and/or to comply with applicable laws</li>
                </ol>
                <h3>DISCLOSURE OF USER INFORMATION</h3>
                <p>The User Information may be disclosed to such third parties as may be required to:</p>
                <ul class="alpha">
                    <li>provide You with the services on the Platform, and may be subject to the practices of such
                        third parties;</li>
                    <li>facilitate the purchase and delivery of the Products on the Platform by You;</li>
                    <li>grow Our business which may include any event of corporate sale, merger, reorganization,
                        sale of assets whereby share such User Information with our affiliates, strategic partners,
                        investors, suppliers, and service providers as the case maybe or ;</li>
                    <li>The User Information may be disclosed to Key shareholders, board, investors (debt or
                        equity), legal or financial advisors. The User Information may be disclosed to the
                        employees, contractors, vendors, partners or affiliated organizations on a need-to-know
                        basis in order to undertake process on the behalf of the Company and/or to facilitate smooth
                        functioning of our services, such as providing payment function, supply chain, network
                        services, IT support, customer service etc.;</li>
                    <li>Any legal, governmental or regulatory authority if it is required to comply with any legal
                        order or process, issued or commenced, in accordance with the applicable laws</li>
                </ul>
                <p>We will share your User information with third parties only in the ways that are described in
                    this Policy. We may use the individual data and behavior patterns combined with personal
                    Information to provide you with personalized content, and better your learning objectives.</p>
                <p>The Company may provide and utilise the User Information and data collected to certain third
                    parties for undertaking data analysis via third party analytical tools. The third party
                    analytical tools are utilised in order to analyse the data and information to personalize, drive
                    insights and thereby provide a better performance, improve the quality of features and provide
                    seamless services to the User.</p>
                <ol>
                    <li>You acknowledge that some of the recipients of your information may be located outside your
                        country of residence. Where we transfer your information to such third parties, we will
                        endeavour to ensure that your information remains secure from unauthorized disclosure or
                        theft. To the extent required under data privacy laws of the applicable jurisdiction, we may
                        transfer such information pursuant to consent or through applicable data protection
                        agreements or other mechanisms permitted under such laws.</li>
                </ol>
                <h3>SECURITY PRECAUTIONS</h3>
                <p>To prevent any form of unlawful interception or misuse of User Information, We use reasonable
                    physical, electronic, and managerial procedures to safeguard and secure the User Information
                    collected. We use reasonably secure and technologically appropriate measures, in compliance with
                    the Information Technology Act, 2000 and the rules related thereto to protect You against loss
                    or misuse of Your applicable User Information including internal reviews of data collection,
                    storage and processing practices and other reasonable security measures which are equivalent to
                    security measures that Seashels uses to protect its own confidential information. We have in
                    place a secure server for all Your transactions on the Platform, which, if required to be
                    accessed, are accessible only by the authorized personnel of Seashels. However as You are aware,
                    no internet site/mobile based application is completely free of security risks and We do not
                    make any representation in respect of the same.</p>
                <p>We do not warrant that Our Platform or any electronic communication made by Us is free from virus
                    or other harmful effects. In the event of any errors in transmission or in the event of Our
                    Platform being inaccessible due to an act of any third party or due to any outage, or any
                    technical or technological failure, or similar reasons beyond our control, We shall not be held
                    liable or responsible for any losses/ damages incurred by you, and you agree that you will not
                    have any claims against us regarding the same. You explicitly agree that Your use of the
                    Platform and/or services is at Your own individual risk. You agree and confirm that Your User
                    Information may be collected, used, transferred, processed and stored in the manner stipulated
                    in this Privacy Policy. You hereby confirm that You have been made aware of the security
                    measures undertaken by Us and You expressly consent to Us collecting, storing, handling,
                    processing and using Your User Information.</p>
                <h3>Links to other third party sites</h3>
                <p>The Platform may provide third party information and links to other platforms that are not
                    affiliates of or operated or controlled by Seashels including but not limited to payment
                    gateways or social networking platforms. Seashels is not responsible for any form of
                    transmission, whatsoever, received by You from any third party platform and accordingly does not
                    make any representations concerning the privacy practices or other policies of such third party
                    Platforms. Under no circumstances shall Seashels be deemed to control or guarantee the accuracy,
                    integrity, or quality of the information, data, text, software, sound, photographs, graphics,
                    videos, cookies, messages or other materials available on such platforms. Any User Information
                    provided by You to such third party Platforms shall be governed in accordance with the privacy
                    policies of such platforms and it is recommended that You review the privacy policy of such
                    platforms prior to using such platforms.</p>
                <h3>Data Retention Guideline</h3>
                <p>Company may retain device information for a period of up to 48 months, unless otherwise required
                    by law or applicable contract.</p>
                <p>With respect to the account information, registration data and/or addresses provided for
                    facilitating purchase of products, the same will be retained until your specific instructions to
                    delete such data. You can place this request to delete your data by writing to us at
                    privacy@seashels.com Company may retain transaction data and certain account information of
                    Yours as may be required to comply with its legal obligations, resolve disputes (including
                    billing) and enforce agreements.</p>
                <p>Company may retain the information it obtains about you as per the instructions of its customers
                    or partners who provide such information or as required to fulfil our legal, compliance and
                    contractual obligations</p>
                <p>After the applicable retention period, Company may retain and use such data in an aggregated
                    format, as necessary for internal analytical purposes, to comply with its legal obligations,
                    resolve disputes (including billing) and enforce agreements.</p>

                <h3>Change in Privacy Policy</h3>
                <p>We reserve the right to update, modify and amend any of the terms of Our Privacy Policy, at any
                    time without prior intimation to You. We will post these changes on Our Platform for Your
                    information. These changes will become effective immediately on posting. We shall not be liable
                    for any failure or negligence on Your part to review the updated Privacy Policy before accessing
                    or availing the Platform for availing the services. Your continued use of the Platform,
                    following changes to the Privacy Policy, will constitute Your acceptance of those changes.</p>

                <h3>Intellectual Property rights</h3>
                <p>Our Platform and all Content on Our Platform, including graphics, text, icons, interfaces, audio
                    clips, logos, images, reviews, comments and software is the property of Seashels and/or its
                    Content suppliers and is protected by Indian and international copyright laws. Any use,
                    including the reproduction, modification, distribution, transmission, republication, display or
                    performance, of the Content on this Platform can only be made with the express permission of
                    Seashels. All other trademarks, brands and copyrights other than those belonging to Seashels,
                    belong to their respective owners and are their property.</p>
                <h3>Force Majeure</h3>
                <p>Notwithstanding anything contained in this Privacy Policy or elsewhere, Seashels shall not be
                    held responsible for any loss, damage or misuse of Your User Information, if such loss, damage
                    or misuse is attributable to a Force Majeure Event. A “Force Majeure Event” shall mean any event
                    that is beyond the reasonable control of Seashels and shall include, without limitation,
                    sabotage, fire, flood, explosion, pandemic, acts of God, civil commotion, strikes or industrial
                    action of any kind, riots, insurrection, war, acts of government, computer hacking, technical
                    snags, outage, unauthorized access to computer data and storage device, technical snags and
                    breach of security and encryption.</p>
                <h3>Disclosures Required By Law</h3>
                <p>Seashels reserves the right to disclose User Information when required by law. We will disclose
                    such User Information wherein We have a good-faith belief that it is necessary to comply with a
                    court order, ongoing judicial proceeding, or other legal process served on Us or to exercise Our
                    legal rights or defend against legal claims.</p>
                <h3>Contacting the Platform</h3>
                <p>If You have any questions, or grievances regarding (i) this Privacy Policy; (ii) practices of
                    this Platform, or (iii) Your dealings with this Platform or believe that We have not adhered to
                    it or or (iv) wish to unsubscribe from receiving communications via SMS, calls and email or (v)
                    wish to exercise your rights, as granted under the applicable privacy laws, You can contact us
                    at <a href="#">crm@seashels.com.</a></p>
                <p>Alternatively, you can chat with us on the help desk chat option available in the Application.
                </p>
                <p>This Privacy Policy shall be read in conjunction with the Platform’s Terms of Service and all the
                    relevant provisions of the Platform’s Terms of Service (including but not limited to
                    indemnification, limitation of liability, governing law and dispute resolution) shall apply to
                    this Privacy Policy and be deemed to be incorporated herein by reference.</p>
            </div>

        </div>
    </div>
    );
};

export default PrivacyPolicy;