import React from 'react';
import { Link } from 'react-router-dom';
// import './Footer.css';

// import '../js/jquery.js';
// import '../js/custom-jquery.js';

const Footer = () => {
    return (
        <footer id="new">
            {/* <div className="container">
                <div className="col-3 one">
                    <img src={`${process.env.PUBLIC_URL}/images/topnavi.png`} alt="logo" />

                    <p><span>Address:</span> <Link to="https://www.google.com/maps/search/100+XYZ+Park+Avenue,+East+58st+Street,+Mumbai,+India/@19.082502,72.7163774,11z/data=!3m1!4b1?entry=ttu" target="_blank">100 XYZ Park Avenue, East 58st Street, Mumbai, India</Link></p>
                    <p><span>Email:</span> <Link to="mailto:abd100@gmail.com">abd100@gmail.com</Link></p>
                    <p><span>Phone:</span> <Link to="tel:12345 67890">12345 67890</Link></p>
                    <div className="social-media">
                        <ul>
                            <li><Link to="#" target="_blank"><img src="/images/instagram.png" alt=""></img></Link></li>
                            <li><Link to="#" target="_blank"><img src="/images/facebook.png" alt=""></img></Link></li>
                            <li><Link to="#" target="_blank"><img src="/images/p-interest.png" alt=""></img></Link></li>
                            <li><Link to="#" target="_blank"><img src="/images/tiktok.png" alt=""></img></Link></li>
                        </ul>
                    </div>
                </div>
                <div className="col-3 two">
                    <h4>About Modimal</h4>
                    <ul>

                        <li><Link to="collection.html">Collection</Link></li>
                        <li><Link to="sustainability.html">Sustainability</Link></li>
                        <li><Link to="privacy-Policy.html">Privacy Policy</Link></li>
                        <li><Link to="support-system.html">Support System</Link></li>
                        <li><Link to="terms-condition.html">Terms & Condition</Link></li>
                        <li><Link to="copyright-notice.html">Copyright Notice</Link></li>

                    </ul>
                </div>
                <div className="col-3 three">
                    <h4>Help & Support</h4>
                    <ul>

                        <li><Link to="orders-shipping.html">Orders & Shipping</Link></li>
                        <li><Link to="returns-refunds.html">Returns & Refunds</Link></li>
                        <li><Link to="FAQs.html">FAQs</Link></li>
                        <li><Link to="contact-us.html">Contact Us</Link></li>
                    </ul>
                </div>
                <div className="col-3 four">
                    <h4>Join Up</h4>
                    <ul>

                        <li><Link to="modimal-club.html">Modimal Club</Link></li>
                        <li><Link to="careers.html">Careers</Link></li>
                        <li><Link to="visit-us.html">Visit Us</Link></li>
                    </ul>
                </div>
            </div> */}
            {/* <div className="footer-bottom">
                &copy; {new Date().getFullYear()} E-Commerce. All rights reserved.
            </div> */}




            <div className="container">
                <div className="logo">
                    <Link to="/"><img src="/images/topnavi.png" alt="" /></Link>
                </div>
                <div className="ftr-menu">
                    <ul>
                        <li>
                            <Link to="#">Contact</Link>
                        </li>
                        <li>
                            <Link to="/privecy-policy">Privacy Policy</Link>
                        </li>
                        <li>
                            <Link to="/terms-services">Terms of Services</Link>
                        </li>
                        <li>
                            <Link to="#">Shipping & Returns</Link>
                        </li>
                        <li>
                            <Link to="/how-to-delete-your-account">Seashels App Account Delete Guide</Link>
                        </li>
                    </ul>
                </div>
                <div className="ftr-contact">
                    <form action="">
                        <div className="field group">
                            <div className="field-row">
                                <input type="text" placeholder="Your full name*" />
                            </div>
                            <div className="field-row">
                                <input type="text" placeholder="Email *" />
                            </div>
                        </div>
                        <div className="field">
                            <div className="field-row">
                                <input type="text" placeholder="Telephone *" />
                            </div>
                        </div>
                        <div className="field">
                            <div className="field-row">
                                <textarea name="" id="" placeholder="Message"></textarea>
                            </div>
                        </div>
                        <div className="field">
                            <button type="submit">Submit</button>
                        </div>
                    </form>
                </div>
                <div className="copy-right">
                    <p>© 2024 Seashels. All rights reserved</p>
                </div>
            </div>


        </footer>
    );
};

export default Footer;
