import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import { GetCountries, GetState, GetCity } from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
import PostalCodeSelector from '../PostalCodeSelector';
import CheckoutFormSkelton from './CheckoutFormSkelton';

const CheckoutForm = ({ existingAddress, addAddress }) => {
    const [phoneValue, setPhoneValue] = useState('');
    const [countryCode, setCountryCode] = useState('US');
    const [countryId, setCountryId] = useState(0);
    const [countryName, setCountryName] = useState(''); 
    const [stateId, setStateId] = useState('');
    const [cityId, setCityId] = useState('');
    const [cityName, setCityName] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [countriesList, setCountriesList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state
console.log(existingAddress,'existingAddress');
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: { errors },
    } = useForm({
        defaultValues: {
            name: "",
            email: "",
            phone: "",
            address_1: "",
            address_2: "",
            state: "",
            zipcode: "",
            city: "",
            country: "", 
        },
    });

// Initialize form with existing address
useEffect(() => {
    if (existingAddress?.user_address?.length > 0) {
        const existing = existingAddress.user_address[0] || {};
        console.log(existing, 'Existing Address Data');

        // Set fields from existing address
        setPhoneValue(existing.phone || '');
        setPostalCode(existing.zipcode || '');
        setCountryCode(existing.country_code || ''); 
        setCountryName(existing.country_name || ''); 
        setStateId(existing.state || '');
        setCityName(existing.city || '')

        // Reset form with existing values
        reset({
            name: existing.shipping_name || "",
            email: existing.user_address_email || "",
            address_1: existing.address_1 || "",
            address_2: existing.address_2 || "",
            state: existing.state || "",
            zipcode: existing.zipcode || "",
            city: existing.city || "",
            country: existing.country_name || "", // Use country_name
        });

        // Fetch countries
        GetCountries().then((result) => {
            setCountriesList(result);

            // Find the country that matches the existing country_code
            const matchedCountry = result.find(country => country.iso2 === existing.country_code);
            console.log(result, 'Fetched Countries');

            if (matchedCountry) {
                setValue('country', matchedCountry.iso2); 
                setCountryId(matchedCountry.id); 
                setCountryCode(matchedCountry.iso2);

                console.log(matchedCountry, 'Matched Country');

                // Fetch states for the matched country after setting the countryId
                GetState(matchedCountry.id).then((stateResult) => {
                    setStateList(stateResult);
                    setValue('state', existing.state || ""); // Set state value after fetching states
console.log(stateResult,'stateResult');
  // Find the country that matches the existing country_code
  const matchedState = stateResult.find(state => state.name === existing.state);
  console.log(matchedState, 'Fetched matchedState');


  if (matchedState) {
    setValue('state', matchedState.name); // Set country name in form
    setStateId(matchedState.name); // Set countryId to the matched iso2

    console.log(matchedState, 'Matched Country');
                      // Fetch cities for the existing state after confirming stateId
        GetCity(countryId, matchedState.id).then((CityResult) => {
  console.log(CityResult, 'Fetched CityResult');

            setCityList(CityResult);
            setValue('city', existing.city || ""); // Set city value after fetching cities
 // Find the country that matches the existing country_code
  const matchedCity = CityResult.find(city => city.name === existing.city);

  console.log(matchedCity, 'Fetched matchedCity');

  if (matchedCity) {
    setValue('city', matchedCity.name); 
    setCityId(matchedCity.id); 
    setCityName(matchedCity.name); 

  }
            
        });
    }
                });
            } else {
                setValue('country', ""); 
            }
        });

      
    }
    else
    {
        GetCountries().then((result) => {
            setCountriesList(result);
        
            const matchedCountry = result.find(country => country.iso2 === countryCode);
            console.log(matchedCountry, 'Fetched matchedCountry');
        
            if (matchedCountry) {
                // Set the values for country and its ID
                setValue('country', matchedCountry.iso2);
                setCountryId(matchedCountry.id); 
                setCountryCode(matchedCountry.iso2);
                console.log(matchedCountry, 'Matched Country');
        
                // Fetch states for the matched country
                GetState(matchedCountry.id).then((stateResult) => {
                    setStateList(stateResult);
                    // Set state value after fetching states, use existing stateName or default to empty string
                    setValue('state', ""); 
                }).catch((error) => {
                    console.error('Error fetching states:', error);
                    setStateList([]); // Reset state list in case of an error
                });
            } else {
                // Handle the case where no matched country is found
                console.warn('No matched country found for code:', countryCode);
                setValue('country', ""); // Reset country value if no match
                setCountryId(null); // Reset country ID
                setCountryCode(""); // Reset country code
                setStateList([]); // Reset state list
            }
        }).catch((error) => {
            console.error('Error fetching countries:', error);
            setCountriesList([]); // Reset countries list in case of an error
        });
    }        
}, [existingAddress, reset, setValue]);


    const handlePhoneChange = (value) => {
        const phoneNumber = value.replace(/^\+/, '');
        setPhoneValue(phoneNumber);
        setValue('phone', phoneNumber);
    };

    const handleCountryChange = (country) => {
        console.log(country,'country');
        // return;
        if (country) {
            setCountryId(country.id); 
            setCountryCode(country.iso2); 
            setCountryName(country.name); 
              setValue("country", country.iso2); // Update the form field in react-hook-form
            //   console.log(country,'country');
            //   return;
            setStateId(''); 
            setCityId('');
            setCityList([]); // Reset city list
            GetState(country.id).then((result) => {
                setStateList(result); // Fetch states for selected country
                setValue('state', ''); // Reset state in form
                setValue('city', ''); // Reset city in form
            });
            reset((prev) => ({
                ...prev,
                state: '',
                city: '',
                zipcode: '',
                country: country.iso2, // Set country name in form
            }));
        }
    };

    const handleStateChange = (state) => {
        console.log(state,'state');
        if (state) {
            setStateId(state.name);
            GetCity(countryId, state.id).then((result) => {
                setCityList(result); // Fetch cities for selected state
                setValue('city', ''); // Reset city in form
            });
            reset((prev) => ({
                ...prev,
                city: '',
                state: state.name,
            }));
        }
    };

    const handleCityChange = (event) => {
        console.log(event,'event');
        // const city = cityList[event.target.selectedIndex]; // Access the city based on selected index
        
        if (event) {
            setCityId(event.id);
            setCityName(event.name);
            reset((prev) => ({
                ...prev,
                city: event.name,
            }));
        }
    };

    const handleAddressFetched = async (postalCode) => {
    
  // Function to fetch full address based on postal code (forward geocoding)
    if (!postalCode) {
      setError('Postal code is empty');
    //   onAddressFetched(null); // Clear the address if postal code is empty
      return;
    }

    try {
        const response = await fetch(
            `https://nominatim.openstreetmap.org/search?postalcode=${postalCode}&format=json&addressdetails=1`
          );
          const data = await response.json();
          console.log(data, 'Response from Nominatim');
          console.log(data.length , 'Response from data.length ');
          
          if (data.length > 0) {
            setError(null); // Clear any previous errors
          
            // Iterate over the data array and check for matching country, state, and city
            const matchedData = data.find((item) => {
              const { address } = item;
          
              // Ensure address object and its properties exist before accessing them
              const cityName = address?.village ||address?.city || address?.town ||  address?.suburb || address?.state_district ||null;
              const stateName = address?.state || null;
              const countryName = address?.country || null;
          
              // Match country, state, and city
              return countryName  && stateName  && cityName ;
            });
          
           
            console.log(matchedData,'matchedData')
              // Destructure the matched address object
              const { address } = matchedData;
        //   return;
              const cityName =  address?.village ||address?.city || address?.town ||  address?.suburb || address?.state_district ||null;
              const districtName = address?.state_district || address?.district || null;
              const stateName = address?.state || null;
              const countryName = address?.country || null;
              const country_code = address?.country_code || null;
          
              // Set the values you need here (you can update the form fields, state, etc.)
              console.log('Matched address:', { cityName, districtName, stateName, countryName, country_code });
        //    return true;
            GetCountries().then((result) => {
                setCountriesList(result);
                const matchedCountry = result.find(country => country.name === countryName);
                console.log(matchedCountry, 'posttal Fetched matchedCountry');
             
                if (matchedCountry) {
                    setValue('country', matchedCountry.iso2);
                    setCountryId(matchedCountry.id); 
                    setCountryCode(matchedCountry.iso2);
    
                    console.log(matchedCountry, 'Matched Country');
    
                    // Fetch states for the matched country
                    GetState(matchedCountry.id).then((stateResult) => {
                        setStateList(stateResult);
                        setValue('state', stateName || ""); // Set state value after fetching states
    
                        console.log(stateResult, 'Fetched States');
    
                        // Find the state that matches the fetched state name
                        const matchedState = stateResult.find(state => state.name === stateName);
       
                        if (matchedState) {
                            setValue('state', matchedState.name); // Set state in form
                            setStateId(matchedState.name); // Set the state ID
    
                            console.log(matchedState, 'Matched State');
                          
                            // Fetch cities for the matched state
                            GetCity(matchedCountry.id, matchedState.id).then((CityResult) => {
                                setCityList(CityResult);
                                setValue('city', cityName || ""); // Set city value after fetching cities
                                console.log(CityResult, 'Fetched Cities');
      
                                // Find the city that matches the fetched city name (if exists)
                                const matchedCity = CityResult.find(city => city.name ===cityName);
                          
                                if (matchedCity) {
                                    setValue('city', matchedCity.name); // Set city in form
                                    setCityId(matchedCity.id); // Set city ID
                                    setCityName(matchedCity.name); // Set the city name
                                    // console.log(matchedCity, 'posttal Fetched matchedCity');
                                    // return;
                                } else {
                                    // Handle the case where the city is missing
                                    setValue('city', ""); // Clear city if not found
                                }
                            });
                        } else {
                            // Handle the case where the state is missing
                            setValue('state', ""); // Clear state if not found
                        }
                    });
                } else {
                    // Handle the case where the country is missing
                    setValue('country', ""); // Clear country if not found
                }
            });
        
    }
        else {
            setError('No results found');
          }
        } catch (err) {
          setError('Error fetching location data');
        }
      
    };
   
console.log(countriesList,'countriesList');
    const onSubmit = (data) => {
        data.phone = phoneValue;
        data.country_code = countryCode; // Add country code to data
        data.country = countryName; // Add country name to data

        console.log(data, 'data with phone value');
// return;
        if (existingAddress?.user_address?.length > 0) {
            const existing = existingAddress.user_address[0] || {};
            const isUpdate = {
                address_id: existing.address_id || "",
                ...data,
            };
            addAddress(isUpdate);
        } else {
            addAddress(data);
        }
    };
 // Set loading to false after fetching countries
 useEffect(() => {
    if (countriesList.length > 0) {
        setLoading(false);
    }
}, [countriesList]);
    return (
        <div className="checkout-form">
        {loading ? (
            <CheckoutFormSkelton /> // Show skeleton while loading
        ) : (
        <form className="checkout" onSubmit={handleSubmit(onSubmit)}>
            <div className="field-row two">
                <div className="field">
                    <input 
                        type="text" 
                        placeholder="Full Name" 
                        {...register("name", { required: true })} 
                    />
                    {errors.name && <p className="errorMsg">Full Name is required.</p>}
                </div>
            </div>

            <div className="field-row">
                <div className="field">
                    <input
                        type="text"
                        placeholder="Email"
                        {...register("email", {
                            required: true,
                            pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                        })}
                    />
                    {errors.email && <p className="errorMsg">Email is required.</p>}
                    {errors.email?.type === "pattern" && <p className="errorMsg">Email is not valid.</p>}
                </div>
            </div>

            <div className="field-row">
                <div className="field">
                    <PhoneInput
                        country={'us'}
                        value={phoneValue}
                        onChange={handlePhoneChange}
                        inputStyle={{ width: '100%' }}
                        disabledCountryCode
                        inputProps={{
                            name: 'phone',
                            autoComplete: 'off',
                        }}
                    />
                    {errors.phone && <p className="errorMsg">Phone number is required.</p>}
                </div>
            </div>

            <div className="field-row">
                <div className="field">
                    <input 
                        type="text" 
                        placeholder="Address 1" 
                        {...register("address_1")} 
                    />
                </div>
            </div>

            <div className="field-row">
                <div className="field">
                    <input 
                        type="text" 
                        placeholder="Address 2" 
                        {...register("address_2")} 
                    />
                </div>
            </div>

            <div className="field-row">
            <div className="field">
  <select
    {...register("country", { required: true })} 
    onChange={(e) => {
      const selectedIndex = e.target.selectedIndex;
      const selectedValue = e.target.value;
      
      // Handle the "Select Country" option separately
      if (selectedValue === "") {
        handleCountryChange(null); // Or any fallback logic
      } else {
        handleCountryChange(countriesList[selectedIndex - 1]); // Subtract 1 to adjust for the placeholder option
      }
    }} 
    value={countryCode}
    defaultValue={countryCode}
  >
    <option value="">Select Country</option>
    {countriesList.map((item, index) => (
      <option key={index} value={item.iso2}>
        {item.name}
      </option>
    ))}
  </select>
  {errors.country && <p className="errorMsg">Country is required.</p>}
</div>


            </div>

            <div className="field-row">
            <div className="field">
    <select
        {...register("state", { required: true })} 
        onChange={(e) => {
            const selectedValue = e.target.value;
            const selectedIndex = e.target.selectedIndex;

            // Handle the "Select State" case separately
            if (selectedValue === "") {
                handleStateChange(null); // Pass null or handle it as per your logic
            } else {
                handleStateChange(stateList[selectedIndex - 1]); // Adjust index to account for the placeholder
            }
        }}
        value={stateId}
    >
        <option value="">Select State</option> {/* Add placeholder */}
        {stateList.map((item, index) => (
            <option key={index} value={item.name}>
                {item.name}
            </option>
        ))}
    </select>
    {errors.state && <p className="errorMsg">State is required.</p>}
</div>

            </div>

            <div className="field-row two">
                <div className="field">
                    <input 
                        type="text" 
                        placeholder="Postal Code" 
                        name="zipcode" 
                        {...register("zipcode", { required: true })} 
                        onChange={(e) => handleAddressFetched(e.target.value)}
                    />
                    {errors.zipcode && <p className="errorMsg">Postal Code is required.</p>}
                   
                </div>
                <div className="field">
    <select
        {...register("city", { required: true })} 
        onChange={(e) => {
            const selectedValue = e.target.value;
            const selectedIndex = e.target.selectedIndex;

            // Handle the "Select City" case
            if (selectedValue === "") {
                handleCityChange(null); // Pass null when no city is selected
            } else {
                handleCityChange(cityList[selectedIndex - 1]); // Adjust for placeholder
            }
        }}
        value={cityName}
    >
        <option value="">Select City</option> {/* Add placeholder */}
        {cityList.map((item, index) => (
            <option key={index} value={item.name}>
                {item.name}
            </option>
        ))}
    </select>
    {errors.city && <p className="errorMsg">City is required.</p>}
</div>

            </div>

            <div>
                <button type='submit'>
                    {existingAddress?.user_address?.length ? "Update Address" : "Save and Continue"}
                </button>
            </div>
        </form>

)}
</div>
);
};

export default CheckoutForm;
