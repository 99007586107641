import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import api from '../../api';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import ShopFilter from './ShopFilter';

const Shop = ({
    apiEndpoint = '/filters/get_sub_categories_list',
    pageTitle = ''
}) => {
    const { category_id, gender_id, segment_id, sub_category_id } = useParams();
    const { logout } = useAuth();
    const navigate = useNavigate();
    const [storeData, setStoreData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [sortOption, setSortOption] = useState('low');
    const [sizeOption, setSizeOption] = useState('Size');
    const [priceOption, setPriceOption] = useState('Price');
    const [colorOption, setColorOption] = useState('Color');
    const [filterOptions, setFilterOptions] = useState({
        sizeOptions: [],
        priceOptions: [],
        colorOptions: [],
    });

    const fetchStoreData = async () => {
        setLoading(true); 
        try {
            const api_token = localStorage.getItem('apiToken');
            const response = await api.get(apiEndpoint, {
                params: {
                    api_token: api_token,
                    segment_id: segment_id || '',
                    gender_id: gender_id || 2,
                    category_id: category_id || '',
                    page: 0,
                },
            });

            if (response.status === 401) {
                logout();
                navigate('/home');
                return;
            }

            if (response.status === 200) {
                setStoreData(response.data.data);
            } else {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false); 
        }
    };

    useEffect(() => {
        fetchStoreData();
    }, [apiEndpoint, segment_id, gender_id, category_id]);

    const handleClearFilters = () => {
        setSortOption('low');
        setSizeOption('Size');
        setPriceOption('Price');
        setColorOption('Color');
    };

    if (loading) {
        return (
            <div className="main-content not-home">
                <Skeleton count={5} height={200} />
                <Skeleton count={5} height={100} />
                <Skeleton count={5} height={100} />
                <Skeleton height={350} />
                <Skeleton count={5} height={150} />
            </div>
        );
    }
    return (
        <div className="main-content not-home  shop-section-data">
            <ShopFilter
                SubCategoryList_data={storeData}
            />
        </div>
    );
};

export default Shop;
