// src/components/GuestLogin.js
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const GuestLogin = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const handleGuestLogin = async () => {
        setLoading(true);
        try {
            const response = await axios.post(
                'https://development.seashels.com:7777/api/guest/guest_login',
                {
                    app_version: '1.0',
                    country_code: 'IN',
                    device_token: '',
                    device_type: 'web',
                }
            );
            if (response.data && response.data.api_token) {
                localStorage.setItem('apiToken', response.data.api_token);
                localStorage.setItem('userRole', 'guest'); // Store the role
                navigate('/home'); // Redirect to home or any other page
            }
        } catch (err) {
            setError('Guest login failed.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <button onClick={handleGuestLogin} disabled={loading}>
                {loading ? 'Logging in...' : 'Login as Guest'}
            </button>
            {error && <div>{error}</div>}
        </div>
    );
};

export default GuestLogin;
