import React, { useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './VisitStoreDigitally.css';
import { Link } from 'react-router-dom';
import LazyLoadImage from '../LazyLoadImage'; // Import the LazyLoadImage component for lazy loading images

const VisitStoreDigitally = ({ digital_stores = {}, loading }) => { // Default to empty object
    const [view, setView] = useState('carousel'); // Default view

    // Check if carousel exists and has items
    const hasCarousel = digital_stores.carousel?.length > 0;

    // Owl Carousel settings
    const carouselSettings = {
        items: 1,  // Only show one item at a time
        loop: hasCarousel && digital_stores.carousel.length > 1, // Disable loop if there's only one image
        autoplay: hasCarousel && digital_stores.carousel.length > 1, // Disable autoplay if there's only one image
        autoplayTimeout: 5000,
        dots: true,
    };

    return (
        <section className='store'>
            <div className="visit-store-digitally">
                <h2>
                    {loading ? <Skeleton width={200} /> : digital_stores.heading}
                    {!loading && <Link to="/stores" className="read-more"></Link>}
                </h2>

                <div className="view-toggle" style={{ display: 'none' }}>
                    <button onClick={() => setView('carousel')} disabled={view === 'carousel'}>
                        Carousel View
                    </button>
                    <button onClick={() => setView('grid')} disabled={view === 'grid'}>
                        Grid View
                    </button>
                </div>

                {view === 'carousel' ? (
                    <div className="carousel-section">
                        {loading ? (
                            <Skeleton height={800} count={1} />
                        ) : (
                            hasCarousel ? ( // Check if carousel exists before rendering
                                <OwlCarousel className="store-carousel owl-carousel owl-theme" {...carouselSettings}>
                                    {digital_stores.carousel.map((banner, index) => (
                                        <div key={index} className="item">
                                            <Link to={banner.store_uuid ? `/visit-store/${banner.store_uuid}` : "/stores"} className="banner-btn">
                                                <LazyLoadImage
                                                    thumbnailSrc={banner.profile_image_thumb} // Low-res or placeholder image
                                                    fullImageSrc={banner.profile_image} // Full image source
                                                    alt={banner.store_name || "Store Image"}
                                                    className="carousel-image"
                                                    onError={(e) => (e.target.src = 'https://via.placeholder.com/300')}
                                                />
                                            </Link>
                                        </div>
                                    ))}
                                </OwlCarousel>
                            ) : (
                                <p>No digital stores available.</p>
                            )
                        )}
                    </div>
                ) : (
                    'Store Not Found'
                )}
            </div>
        </section>
    );
};

export default VisitStoreDigitally;
