// export const CountryCodeString = {
//     'IN': '+91',
//     'US': '+1',
//     // Add more country codes here
// };
// import CountryCodeString from './countryCodes.json';
import CountryCodeString from '../components/currencySelector/countryCodes.json';

export const convertPhoneNumber = (phoneNumber) => {
    const [country, number] = phoneNumber.split('-');
    const countryCode = CountryCodeString[country];

    if (countryCode) {
        return `${countryCode}-${number}`;
    } else {
        throw new Error('Invalid country code');
    }
};

export const CountryCodeWithString = (phoneNumber) => {
    const [country] = phoneNumber.split('-');
    const countryCode = CountryCodeString[country];

    if (countryCode) {
        return `${countryCode}`;
    } else {
        throw new Error('Invalid country code');
    }
};
