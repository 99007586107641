import React, { useEffect, useState } from 'react';
import { getClientIp } from '../utils/getClientIp'; // Adjust path as needed

const CheckIp = () => {
    const [ipAddress, setIpAddress] = useState(null);

    useEffect(() => {
        const fetchIp = async () => {
            const ip = await getClientIp();
            setIpAddress(ip);
        };

        fetchIp();
    }, []);


    return <div>Your IP Address: {ipAddress}</div>;
};

export default CheckIp;
