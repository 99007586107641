// Alert.js
import React from 'react';
import { Link } from 'react-router-dom';
// import './Alert.css'; // Ensure your CSS file is properly linked

const alerts = [
    { id: 1, title: "A.BOCCA", message: "New items in store", time: "3 days ago" },
    { id: 2, title: "Adidas", message: "20% Off", time: "3 days ago" },
    { id: 3, title: "Zara", message: "Exclusive Offer -10% Off...", time: "2 days ago" },
    { id: 4, title: "Puma", message: "New Summer Collection", time: "2 days ago" },
    { id: 5, title: "CK", message: "Exclusive Offer -10% Off...", time: "1 day ago" },
    { id: 6, title: "Adidas", message: "New items in store", time: "1 day ago" },
    { id: 7, title: "Puma", message: "New items in store", time: "19 h ago" },
    { id: 8, title: "Adidas", message: "New items in store", time: "1 h ago" }
];

const Alert = () => {
    return (
        <div className="main-content not-home" >
            <div className="alert-page">
                <div className="alert-sidebar">
                    <div className="alert-count">
                        50 Alerts
                        <span className="tooltip">
                            <img src="/images/info.png" alt="Info" />
                        </span>
                    </div>
                    <ul>
                        {alerts.map(alert => (
                            <li key={alert.id}>
                                <Link to="#">
                                    <div className="alert-details">
                                        <h4>{alert.title}</h4>
                                        <p>{alert.message}</p>
                                        <span>{alert.time}</span>
                                    </div>
                                </Link>
                                <div className="alert-button">
                                    <Link to="#" className="btn">Accept</Link>
                                    <Link to="#" className="btn">Reject</Link>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Alert;
