import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useCurrency } from '../../context/CurrencyContext';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import api from '../../api'; // Import axios or your preferred HTTP client
import { useAuth } from '../../context/AuthContext'; 
import LazyLoadImage from '../LazyLoadImage'; // Adjust the path as needed
import LoadMore from '../LoadMore/LoadMore'; // Load the LoadMore component

const ITEMS_PER_PAGE = 54; // Number of items per page
const defaultImage = 'https://via.placeholder.com/150';

const StoreFilterProductListing = ({
    storeUuid,
    sortOption,
    sizeOption,
    priceOption,
    colorOption,
    segmentId,
    genderId,
    categoryList,
    productData,
    onClearFilters,
    loading: propLoading, // Use propLoading instead of loading to avoid conflict
}) => {
    const { logout } = useAuth();

    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState('All');
    const [loading, setLoading] = useState(propLoading);
    const { selectedCurrency } = useCurrency(); // Use selectedCurrencyCode if necessary
    const [products, setProducts] = useState([]);
    const [error, setError] = useState(null);
    const [loadedItemCount, setLoadedItemCount] = useState(0); // To keep track of the items loaded so far
    const [totalItems, setTotalItems] = useState(0); // Total items available from the API
    const [loadingMore, setLoadingMore] = useState(false); // For the loading spinner
    const [currentPage, setCurrentPage] = useState(1);
    const [isSuiData, setIsSuiData] = useState(false);

    const fetchProducts = async (append = false) => {
        try {
            if (!storeUuid) return;

            if (!append) {
                setLoading(true);
                setLoadedItemCount(0); // Reset loaded items count only when starting a new fetch
                setCurrentPage(1); // Reset page number if not appending
            } else {
                setLoadingMore(true);
            }

            const api_token = localStorage.getItem('apiToken');
            const currencyCode = localStorage.getItem('currencyCode');

            const params = {
                api_token: api_token,
                page: Math.floor(loadedItemCount / ITEMS_PER_PAGE),
                tag_type: 'product',
                size_list: sizeOption,
                color_list: colorOption,
                price_min: priceOption ? priceOption.split('-')[0] : '',
                price_max: priceOption ? priceOption.split('-')[1] : '',
                segment_id: segmentId,
                gender_id: genderId,
                category_list: categoryList,
                store_uuid: storeUuid,
                currency_code: currencyCode,
                per_page: ITEMS_PER_PAGE,
                sort_order: sortOption,
            };

            const response = await api.get('/filters/get_skus_for_filter', { params });

            if (response.status === 200) {
                const data = response.data;
                const processedProducts = data.sui_data || data.sku_data || [];
                const totalItems = data.total_count;

                setProducts((prevProducts) =>
                    append ? [...prevProducts, ...processedProducts] : processedProducts
                );

                const newLoadedCount = append ? loadedItemCount + processedProducts.length : processedProducts.length;

                setLoadedItemCount(newLoadedCount);
                setTotalItems(totalItems);
                setIsSuiData(data.sui_data);

                // Increment page number if appending
                if (append) {
                    setCurrentPage((prevPage) => prevPage + 1);
                }
            }
            else if (response.status === 401) {
                logout();
                navigate('/home')

            }
            else {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
        } catch (error) {
            setError('Failed to fetch products');
        } finally {
            setLoading(false); // Set loading to false after initial fetch
            setLoadingMore(false); // Set loadingMore to false after fetching more items
        }
    };

    useEffect(() => {
        if (!productData) {
            fetchProducts(false);
        } else {
            setProducts(productData.sui_data || []);
            setTotalItems(productData.total_count || 0);
            setLoadedItemCount(productData.sui_data?.length || 0);
        }
    }, [
        storeUuid, sortOption,
        sizeOption,
        priceOption,
        colorOption,
        segmentId,
        genderId,
        categoryList,
        productData,
    ]);

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
        // Add logic to handle tab click events if needed
    };

    const handleProductClick = (productSui) => {
        navigate(`/product/${productSui}`);
    };

    const loadMoreProducts = () => {
        fetchProducts(true); // Append products when "View More" is clicked
    };

    if (loading && products.length === 0) {
        return (
            <div id="filter-products">
                <div className="container">
                    <ul className="skeleton-list">
                        {Array.from({ length: 8 }).map((_, index) => (
                            <li key={index}>
                                <Skeleton width={300} height={400} />
                                <h4><Skeleton width={200} /></h4>
                                <div className="price-value">
                                    <Skeleton width={100} />
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        );
    }

    if (error) {
        return <div className="error">{error}</div>;
    }

    return (
        <div id="filter-products">
            <div className="container">
                <ul>
                    <li className={activeTab === 'All' ? 'active' : ''}>
                        <a href="#" onClick={(e) => { e.preventDefault(); onClearFilters(); }}>All</a>
                    </li>
                </ul>

                <div className="also-like latest">
                    {products.length > 0 ? (
                        <ul>
                            {products.map((product) => {
                                const imageUrl = isSuiData ? product.image_url : product.image_url_thumb;
                                const SUI_data = isSuiData ? product.SUI : product.sui;
                                const title = isSuiData ? product.product_title : product.sku_title;
                                const total_price = isSuiData ? product.display_final_price : product.display_final_price;
                                const strike_price = isSuiData ? product.display_total_price : product.display_total_price;
                                const discount = isSuiData ? product.platform_discount : product.sku_discount;
                                const currency_symbol = isSuiData ? product.display_currency_symbol : product.display_currency_symbol;
                                console.log(product.product_title, 'product.product_title   title')

                                return (
                                    <li key={product.SUI || product.sui} onClick={() => handleProductClick(product.SUI || product.sui)}>
                                        <Link to="#" className='img-box' onClick={(e) => e.preventDefault()}>
                                            <LazyLoadImage
                                                thumbnailSrc={imageUrl || defaultImage}
                                                fullImageSrc={imageUrl || defaultImage}
                                                alt={title || 'Product image'}
                                                onError={(e) => (e.target.src = defaultImage)}
                                            />
                                            {product.in_stock === 0 && (
                                                <span className="sold-out">Sold Out</span>
                                            )}
                                        </Link>

                                        <div className="description content">
                                            <h4>
                                                <Link to={`/product/${product.SUI || product.sui}`}>{product.product_title || product.sku_title}</Link>
                                            </h4>
                                            <div className="price-value">
                                                {strike_price && strike_price !== total_price && (
                                                    <>
                                                        <span className="strike_price">
                                                            {`${currency_symbol || '$'}${strike_price}`}
                                                        </span>
                                                        <span className="final_price">
                                                            {`${currency_symbol || '$'}${total_price}`}
                                                        </span>
                                                        <span className="discount">
                                                            {`${product.platform_discount || product.sku_discount}% off`}
                                                        </span>
                                                    </>
                                                )}
                                                {(!strike_price || strike_price === total_price) && (
                                                    <span className="final_price">
                                                        {`${currency_symbol || '$'}${total_price}`}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                    ) : (
                        <div className="no-products">No products available</div>
                    )}
                </div>

                {/* Render the LoadMore component */}
                <LoadMore
                    loadedItemCount={loadedItemCount}
                    totalItems={totalItems}
                    onLoadMore={loadMoreProducts}
                    loadingMore={loadingMore}
                />
            </div>
        </div>
    );
};

export default StoreFilterProductListing;
