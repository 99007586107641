// ProductDetails.js
import React, { useEffect, useState, useRef  } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ProductOrderDetails from './ProductOrderDetails';
import ProductPolicy from './ProductPolicy';
import SizeGuide from './SizeGuide';

import RelatedProducts from './RelatedProducts';
import api from '../../api';
import { useCart } from '../../context/CartContext';
import { useAuth } from '../../context/AuthContext';
import Skeleton from 'react-loading-skeleton';
import ProductDetailsImageBlock from './ProductDetailsImageBlock';
import ProductIcons from './ProductIcons'; // Import the new component
import { ToastNotification, notifySuccess, notifyError } from '../toastNotification/ToastNotification';

const ProductDetails = () => {
    const toastRef = useRef();

    const { sui } = useParams();
    const { cartCount, addItemToCart } = useCart();
    const { apiToken, deviceToken,logout } = useAuth();
    const navigate = useNavigate();
    const [product, setProduct] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [storeUuid, setStoreUuid] = useState(null);
    const [activeCollapse, setActiveCollapse] = useState(null);
    const [selectedColor, setSelectedColor] = useState(null);
    const [selectedSize, setSelectedSize] = useState(null);

    const [videoList, setVideoList] = useState([]);
    const [colorList, setColorList] = useState([]);
    const [sizeList, setSizeList] = useState([]);
    const [sizeChartList, setSizeChartList] = useState([]);
    const [chartOptionName, setChartOptionName] = useState('');
    const [isFavorite, setIsFavorite] = useState(false);
    const [isWishlist, setIsWishlist] = useState(false);
    const [systemName, SetsystemName] = useState(false);
    const [currencysybmol, setCurrencysybmol] = useState(false);
    const [inStock, setInStock] = useState(null);
    const [discountedPrice, setDiscountedPrice] = useState(false);
    const [originalPrice, setOriginalPrice] = useState(false);
    const [discountPercentageOff, setDiscountPercentageOff] = useState(false);
console.log(cartCount,'cartCount');
console.log(apiToken,'apiToken');
    useEffect(() => {
        const fetchProductDetails = async () => {
            try {
                const api_token = localStorage.getItem('apiToken');
                const response = await api.get(`/sku/get_sku_details`, {
                    params: {
                        api_token: api_token,
                        sui,
                        is_private: 0,
                    },
                });
                const data = response.data;
                if (data.status === 200) {
                    const productDetails = data.sui_data[0];
                    setStoreUuid(data.store_details.store_uuid);

                    // Handle video data
                    if (productDetails.video_data && productDetails.video_data.length > 0) {
                        setVideoList(productDetails.video_data);
                    }

                    // Handle images
                    if (productDetails.sku_details.image_data.image_data) {
                        convertStringIntoArray(productDetails.sku_details.image_data.image_data);
                    }

                    // Handle pricing
                    const original_price = productDetails.sku_price.price;
                    const discountedPrice = productDetails.sku_price.price_post_discount;
                    const currency = productDetails.sku_price.symbol;
                    const inStock = productDetails.sku_details.in_stock;
                    if (currency) {
                        setCurrencysybmol(currency);
                    }
                    if (original_price) {
                        setOriginalPrice(original_price);
                    }
                    if (discountedPrice) {
                        setDiscountedPrice(discountedPrice);
                    }
                    if (inStock) {
                        setInStock(inStock);
                    }
                    // Logic for pricing and availability
                    if (original_price === discountedPrice) {
                        // No discount
                        if (inStock === '0' || productDetails.sku_details.is_private === '1') {
                            const discountPercentageOff = original_price && discountedPrice
                                ? Math.round(((original_price - discountedPrice) / original_price) * 100)
                                : null;
                            setDiscountPercentageOff(discountPercentageOff);
                            // Not available
                            // Update UI elements accordingly
                        } else {
                            const discountPercentageOff = original_price && discountedPrice
                                ? Math.round(((original_price - discountedPrice) / original_price) * 100)
                                : null;
                            setDiscountPercentageOff(discountPercentageOff);
                            // Available
                            // Update UI elements accordingly
                        }
                    } else {
                        const discountPercentageOff = original_price && discountedPrice
                            ? Math.round(((original_price - discountedPrice) / original_price) * 100)
                            : null;
                        setDiscountPercentageOff(discountPercentageOff);
                        // With discount
                        // Update UI elements accordingly
                    }
                    // Handle color and size options
                    let normalizeColorList = [];

                    if (productDetails.sku_colors && productDetails.sku_colors.length > 0) {

                        normalizeColorList = productDetails.sku_colors;
                    } else if (productDetails.product_variants && productDetails.product_variants.color_list) {

                        normalizeColorList = productDetails.product_variants.color_list.map(colorObj => ({
                            ...colorObj,
                            name: colorObj.color
                        }));
                    }

                    setColorList(normalizeColorList);
                    let normalizedSizeList = [];
                    if (productDetails.sku_sizes && productDetails.sku_sizes.length > 0) {
                        normalizedSizeList = productDetails.sku_sizes;
                    } else if (productDetails.product_variants && productDetails.product_variants.sizes) {
                        // In the second array format, we convert 'size' to 'name'
                        normalizedSizeList = productDetails.product_variants.sizes[0].sizes.map(sizeObj => ({
                            ...sizeObj,
                            name: sizeObj.size
                        }));
                    }

                    setSizeList(normalizedSizeList);
                    // Update favorite and wishlist status

                    const skuDetails_data = productDetails.sku_details;
                    const system_name = skuDetails_data.product_image_name
                        ? skuDetails_data.product_image_name : '';
                    SetsystemName(system_name);
                    // Set state directly from boolean properties
                    setIsWishlist(skuDetails_data.is_wishlist);
                    setIsFavorite(skuDetails_data.is_favorite);
                    // Handle size chart
                    if (productDetails.sku_details.size_chart_list) {
                        setSizeChartList(productDetails.sku_details.size_chart_list);
                    }
                    if (productDetails.sku_details.size_chart_type) {
                        setChartOptionName(productDetails.sku_details.size_chart_type);
                    }
                    // Additional data
                    setAdditionalData(productDetails.sku_details.policy);
                }
                else if (response.status === 401) {
                    logout();
                    navigate('/home');

                }
                if (response.status !== 200) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const productDetails = response.data.sui_data[0];
                setProduct(productDetails);

                // if (productDetails.product_variants?.color_list?.length > 0) {
                //     setSelectedColor(productDetails.product_variants.color_list[0]);
                // }
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchProductDetails();
    }, [sui]);
    useEffect(() => {
        if (sizeList.length > 0) {
            const defaultSize = sizeList.find(size => size.name === 'M') || sizeList[0];
            setSelectedSize(defaultSize);
        }
    }, [sizeList]);
    useEffect(() => {
        if (colorList.length > 0) {
            const defaultcolor = colorList[0];
            setSelectedColor(defaultcolor);
        }
    }, [sizeList]);

    const handleSizeChange = (e) => {
        const newSizeId = e.target.value;
        const newSize = sizeList.find(size => size.size_id === parseInt(newSizeId, 10));
        setSelectedSize(newSize);
    };


    //new logic start
    const convertStringIntoArray = (data) => {
        // Implement your logic to convert string into array
    };

    const setAdditionalData = (policy) => {
        // Implement your logic for additional data
    };

    const getUserAddressList = (param) => {
        // Implement your logic to get user address list
    };

    const getRelatedSku = () => {
        // Implement your logic to get related SKUs
    };

    const handleCollapseToggle = (index) => {
        setActiveCollapse(activeCollapse === index ? null : index);
    };

    const handleColorChange = (color) => {
        setSelectedColor(color);
    };

    const handleDropdownChange = (event) => {
        const selectedColor1 = colorList.find(color => color.color_id === event.target.value);
        setSelectedColor(selectedColor1);

    };

    const addToCart = async () => {
        const api_token = localStorage.getItem('apiToken');
        const currencyId = localStorage.getItem('currencyId');
        const cart_id = localStorage.getItem('cart_id');
        const order_num = localStorage.getItem('order_num');
        const item_count = localStorage.getItem('item_count');

        const payload = {
            api_token,
            cart_id,
            color_id: selectedColor?.color_id?.toString() || '', // Ensure color_id is a string
            currency_id: currencyId?.toString() || '',
            gift_list_id: '',
            gift_message: '',
            order_num,
            quantity: '1',
            receipient_uuid: '',
            size_id: selectedSize?.size_id?.toString() || '',
            sui,
        };

        try {
            const response = await api.post('/cart/add_item_to_cart', payload);

            if (response.data.cart_data.status === 401) {
                logout();
                navigate('/home');
            } else if (response.data.cart_data.status !== 200) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            addItemToCart();

            // Show success toast notification
        
            // Show success toast notification
         
            notifySuccess('Product successfully added to the cart!');


            navigate('/cart');
        } catch (error) {
            console.error('Error adding item to cart:', error);

            // Show error toast notification
           
            notifyError('Error adding product to the cart. Please try again.');

        }
    };

    if (error) {
        return <p>Error: {error}</p>;
    }

    if (loading) {
        return (
            <div className="main-content product-details">
                <div className="order-details">
                    <div className="container">
                        <div className="product-container">
                            <div className="image-block">
                                <div className="product-gallery">
                                    <div className="thumbnails">
                                        <Skeleton height={80} width={80} count={5} />
                                    </div>
                                    <div className="large-image">
                                        <Skeleton height={400} width={400} />
                                    </div>
                                </div>
                                <div className="product-icons">
                                    <div className="icons-row">
                                        <ul>
                                            <li><Skeleton circle height={40} width={40} /></li>
                                            <li><Skeleton circle height={40} width={40} /></li>
                                            <li><Skeleton circle height={40} width={40} /></li>
                                            <li><Skeleton circle height={40} width={40} /></li>
                                        </ul>
                                        <div className="store-btn">
                                            <ul>
                                                <li><Skeleton width={150} height={40} /></li>
                                                <li><Skeleton width={150} height={40} /></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="order-content">
                                <Skeleton height={30} width={300} />
                                <Skeleton height={20} width={200} />
                                <div className="price-details">
                                    <Skeleton height={20} width={100} />
                                    <Skeleton height={20} width={100} />
                                    <Skeleton height={20} width={80} />
                                </div>
                                <div className="color-variation">
                                    <h4>Colors</h4>
                                    <ul>
                                        <li><Skeleton height={20} width={100} /></li>
                                        <li><Skeleton height={20} width={100} /></li>
                                    </ul>
                                </div>
                                <div className="size-variation">
                                    <h4>Size <a href="#" onClick={() => { }}><Skeleton width={100} /></a></h4>
                                    <div id="size-select">
                                        <Skeleton height={30} width={200} />
                                    </div>
                                </div>

                                <SizeGuide
                                    sizeguide={[]}
                                    sizeguideChart={[]}
                                    sizeguideType=""
                                />

                                <div className="product-cta add-cart">
                                    <Skeleton height={40} width={200} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="product-description">
                    <div className="container">
                        <div className="collapse-section">
                            <div className="collapse-heading">
                                <h2><Skeleton height={30} width={200} /></h2>
                                <Skeleton height={20} width={150} />
                            </div>
                        </div>
                    </div>
                </div>
                <RelatedProducts relatedProducts={[]} />
            </div>
        );
    }

    const { sku_details } = product;
    const { description, size_chart_list = [], size_guide_chart = [], size_chart_type = '', policy = [] } = sku_details || {};
    const handleSizeSelectToggle = () => {
        const sizeSelect = document.getElementById('size-select');
        const sizeGuide = document.getElementById('size-guide');

        if (sizeSelect) {
            sizeSelect.style.display = sizeSelect.style.display === 'none' ? 'block' : 'none';
        }

        if (sizeGuide) {
            sizeGuide.style.display = sizeGuide.style.display === 'none' ? 'block' : 'none';
        }
    };
    return (
        <div className="main-content product-details">
            <div className="order-details">
                <div className="container">
                    <div className="product-container">
                        {/* Image Block component */}
                        <ProductDetailsImageBlock imageData={product?.sku_details?.image_data.image_data || []} storeUuid={storeUuid} videoData={videoList} isWishlist={isWishlist}
                            isFavorite={isFavorite} sui={sui}
                            color_id={selectedColor || ''} size_id={selectedSize || ''}
                            systemName={systemName}
                        />
                        {/* Order Content component */}
                        <div className="order-content">
                            <h3 className="product-name">{product.sku_details.product_title} </h3>
                            <p className="short-description" dangerouslySetInnerHTML={{ __html: description }}></p>
                            <div className="price-details">
                                <div className='price-cont'>
                                    {originalPrice !== discountedPrice ? (
                                        <>
                                            <span className="regular-price">
                                                {currencysybmol}{originalPrice}
                                            </span>
                                            <span className="sale-price">
                                                {currencysybmol}{discountedPrice}
                                            </span>
                                            <br />
                                            <span className="discount-value">
                                                {discountPercentageOff ? `${discountPercentageOff}% OFF` : ''}
                                            </span>
                                        </>
                                    ) : (
                                        <span className="sale-price">
                                            {currencysybmol}{discountedPrice}
                                        </span>
                                    )}
                                </div>
                                <ProductIcons
                                    isWishlist={isWishlist}
                                    isFavorite={isFavorite}
                                    videoData={videoList}
                                    storeUuid={storeUuid}
                                    itemId={sui}

                                    color_id={selectedColor || ''} size_id={selectedSize || ''}
                                    systemName={systemName}
                                    imageData={product?.sku_details?.image_data.image_data[0] || []}
                                    description = {description}
                                   title = {product.sku_details.product_title}

                                />
                            </div>
                            {colorList.length > 1 ? (
                                <div className="color-variation" id="color-variation">

                                    <h4>Color</h4>
                                    <select
                                        onChange={handleDropdownChange}
                                        value={selectedColor ? selectedColor.color_id : ''}
                                        className="color-dropdown"
                                    >
                                        {colorList.map((color) => (
                                            <option key={color.color_id} value={color.color_id}>
                                                {color.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            ) : (
                                <div className="color-variation" id="color-variation-ul">
                                    <h4>Color -</h4>
                                    <ul className="color-list">
                                        {colorList.map((color) => (
                                            <li key={color.color_id} data-color_id={color.color_id}>
                                                <a
                                                    href="#"
                                                    className={selectedColor === color ? 'active' : ''}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        handleColorChange(color);
                                                    }}
                                                >
                                                    {color.name}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                            <div className="size-variation">
                                <h4>Size
                                    {size_guide_chart.length === 0 ? (
                                        <div style={{ display: "none" }}>No size data available</div>
                                    ) : (

                                        <a href="#" id="select-size" onClick={handleSizeSelectToggle}>Size Guide</a>
                                    )}
                                </h4>
                                <div id="size-select">
                                    <select
                                        id="size-selector"
                                        value={selectedSize?.size_id || ''}
                                        onChange={handleSizeChange}
                                    >
                                        {sizeList.length === 0 ? (
                                            <option value="">Loading...</option>
                                        ) : (
                                            sizeList.map(sizeObj => (
                                                <option key={sizeObj.size_id} value={sizeObj.size_id}>
                                                    {sizeObj.name}
                                                </option>
                                            ))
                                        )}
                                    </select>
                                </div>
                            </div>

                            <SizeGuide
                                sizeguide={size_chart_list}
                                sizeguideChart={size_guide_chart}
                                sizeguideType={size_chart_type}
                            />

                            <div className="product-cta add-cart">
                                <button onClick={addToCart} className="add-to-cart-button">Add to shopping bag</button>
                            
                            </div>

                            <div className="product-details-collapse product-option">
                                {/* Render the ProductPolicy component */}
                                <ProductPolicy
                                    policies={policy}
                                    discription={product.sku_details.product_title}
                                    product_details={description}
                                />

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Additional content */}
            <div className="latest-arrivals">
                <div className="related-products">
                    <RelatedProducts
                        storeUuid={storeUuid}
                        sui={sui}

                    />
                </div>
            </div>
        </div >
    );
};

export default ProductDetails;
