import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext'; 
import api from '../../api';
import Pagination from '../pagination/Pagination';

import LazyLoadImage from '../LazyLoadImage'; // Adjust the path as needed LazyLoadImage
const ProfileFavorites = ({ apiToken }) => {
    const { logout } = useAuth();

    const [favorites, setFavorites] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(0); // Start with page 0
    const [totalPages, setTotalPages] = useState(1);
    const [perPage, setPerPage] = useState(54); // Default items per page
    const defaultImage = 'https://via.placeholder.com/150';
    const navigate = useNavigate();
    useEffect(() => {
        const fetchFavorites = async () => {
            try {
                setLoading(true);
                // const api_dtes = '82c9d04a44c84ebeb6e202e8b64f177c';
                // let response = await fetch(`https://development.seashels.com:7777/api/favorite/get_favorite?api_token=${api_dtes}&page=${currentPage}&per_page=${perPage}`);
                // if (response.status !== 200) {
                //     throw new Error(`HTTP error! status: ${response.status}`);
                // }

                // const data = await response.json(); // Convert response to JSON

                // setFavorites(data.user_favorite || []);
                // setTotalPages(Math.ceil(data.total_count / perPage)); // Calculate total pages
                const response = await api.get('/favorite/get_favorite', {
                    params: {
                        api_token: apiToken,
                        page: currentPage, // Sending the current page as a parameter
                    },
                });



                 if (response.status === 401) {
                    logout();
                    navigate('/home');
                }
                else if (response.status !== 200) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                setFavorites(response.data.user_favorite || []);
                setTotalPages(Math.ceil(response.data.total_count / perPage)); // Calculate total pages
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchFavorites();
    }, [apiToken, currentPage, perPage]);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleProductClick = (sui) => {
        navigate(`/product/${sui}`);
    };
    if (loading) {
        return <p>Loading favorites...</p>;
    }

    if (error) {
        return <p>Error: {error}</p>;
    }

    return (
        <div className="also-like latest my-profile-sec">
            <ul>
                {/* {favorites.map((favorite, index) => (
                    <li key={index}>
                        <Link to={`/${favorite.type}/${favorite.reference_id}`}>
                            <img src={favorite.image_url_thumb} alt={favorite.product_title} />
                        </Link>
                        <h4>
                            <Link to={`/${favorite.type}/${favorite.reference_id}`}>{favorite.product_title}</Link>
                        </h4>
                        <div className="description">
                            <span className="price">
                                {favorite.display_currency_symbol}{favorite.display_final_price}
                            </span>
                        </div>
                    </li>
                ))} */}
                {favorites.length > 0 ? (
                    favorites.map((product) => (
                        <li key={product.reference_id} onClick={() => handleProductClick(product.reference_id)}>
                            <Link to="#" className="img-box">
                                <LazyLoadImage
                                    thumbnailSrc={product.image_url_thumb}
                                    fullImageSrc={product.image_url}
                                    alt={product.product_title}
                                    onError={(e) => (e.target.src = defaultImage)}
                                />
                                {product.in_stock <= 0 && <span className="sold-out">Sold Out</span>}
                            </Link>

                            <div className="description content">
                                <h4>
                                    <Link to={`/product/${product.reference_id}`}>{product.product_title}</Link>
                                </h4>
                                <div className="price-value">
                                    {product.display_total_price !== product.display_final_price ? (
                                        <>
                                            <span className="strike_price">
                                                {`${product.display_currency_symbol}${product.display_total_price}`}
                                            </span>
                                            <span className="final_price">
                                                {`${product.display_currency_symbol}${product.display_final_price}`}
                                            </span>
                                            <span className="discount">
                                                {`${product.platform_discount}% off`}
                                            </span>
                                        </>
                                    ) : (
                                        <span className="final_price">
                                            {`${product.display_currency_symbol}${product.display_final_price}`}
                                        </span>
                                    )}
                                </div>
                            </div>
                        </li>
                    ))
                ) : (
                    <p>No favorites available.</p>
                )}
            </ul>
            {totalPages > 1 && ( // Show pagination only if more than 1 page
                <Pagination
                    totalPages={totalPages}
                    currentPage={currentPage + 1} // Pagination starts from 1
                    onPageChange={(pageNumber) => handlePageChange(pageNumber - 1)} // Adjust for 0-based index
                />
            )}
        </div>
    );
};

export default ProfileFavorites;
