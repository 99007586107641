import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SortSelect from '../multiSelect/SortSelect';
import MultiSelectSize from '../multiSelect/multiSelectSize';
import MultiSelectColor from '../multiSelect/multiSelectColor';
import MultiSelectPrice from '../multiSelect/multiSelectPrice';
import ClearAllFilterData from '../multiSelect/ClearAllFilterData';
import MultiInputPrice from '../multiSelect/MultiInputPrice';

const StoreTabFilter = ({
    sortOptions,
    sizeOptions,
    priceOptions,
    colorOptions,
    onSortChange,
    onSizeChange,
    onPriceChange,
    onColorChange,
    onClearFilters,
    onPriceClearFilter,
    onSizeClearFilter,
    onColorClearFilter,
    sortOption,
    showAdditionalFilters
}) => {
    // State to manage selected options
    const [selectedSort, setSelectedSort] = useState(sortOption || '');
    const [selectedSize, setSelectedSize] = useState([]);
    const [selectedPrice, setSelectedPrice] = useState([]);
    const [selectedColors, setSelectedColors] = useState([]);
    const [selectedPriceRange, setSelectedPriceRange] = useState(null);

    return (
        <div className="tab-filter">
            <div className="filter-option">
                <h5>Sort By</h5>
                <SortSelect
                    onChange={(value) => {
                        setSelectedSort(value);
                        onSortChange(value);
                    }}
                    data={sortOptions}
                    selectedValue={selectedSort}
                />
                {showAdditionalFilters && (
                    <>
                     <MultiInputPrice 
                selectedPriceValue={selectedPriceRange} 
                onChange={(value) => {setSelectedPriceRange(value);
                    onPriceChange(value);

                } }
                // onPriceClearFilter={() => setSelectedPriceRange(null)} 
                onPriceClearFilter={onPriceClearFilter} 
            />
                        {/* <MultiSelectPrice
                            onChange={(value) => {
                                setSelectedPrice(value);
                                onPriceChange(value);
                            }}
                            data={priceOptions}
                            selectedValue={selectedPrice}
                            onPriceClearFilter={onPriceClearFilter}
                        /> */}
                        <MultiSelectSize
                            onChange={(value) => {
                                setSelectedSize(value);
                                onSizeChange(value);
                            }}
                            data={sizeOptions}
                            selectedSizes={selectedSize}
                            onSizeClearFilter={onSizeClearFilter}
                        />
                        <MultiSelectColor
                            onChange={(value) => {
                                setSelectedColors(value);
                                onColorChange(value);
                            }}
                            data={colorOptions}
                            selectedColors={selectedColors}
                            onColorClearFilter={onColorClearFilter}
                        />
                    </>
                )}
            </div>

            <div className="clear">
                <ClearAllFilterData
                    onClearFilters={onClearFilters}
                    setSelectedColors={setSelectedColors}
                    setSelectedSizes={setSelectedSize}
                    setSelectedPrice={setSelectedPrice}
                    setSelectedPriceRange={setSelectedPriceRange}

                    setSelectedSort={setSelectedSort}
                />
            </div>
        </div>
    );
};

StoreTabFilter.propTypes = {
    sortOptions: PropTypes.array.isRequired,
    sizeOptions: PropTypes.array.isRequired,
    priceOptions: PropTypes.array.isRequired,
    colorOptions: PropTypes.array.isRequired,
    onSortChange: PropTypes.func.isRequired,
    onSizeChange: PropTypes.func.isRequired,
    onPriceChange: PropTypes.func.isRequired,
    onColorChange: PropTypes.func.isRequired,
    sortOption: PropTypes.string.isRequired,
    showAdditionalFilters: PropTypes.bool.isRequired
};

export default StoreTabFilter;
