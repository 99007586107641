// components/StoreVideos.js
import React, { useState, useEffect } from 'react';

import { Link, useNavigate, useParams } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import api from '../../api'; // Import your API utility
import { useAuth } from '../../context/AuthContext'; 
import StoreDynamicLink from './StoreDynamicLink';

// const StoreVideos = ({ storeName, video_data, onProductClick, loading }) => (

const StoreVideos = ({ storeName, thumburldata, video_data, onProductClick, loading, follow_status, storeUuid, storeSellers,storeId }) => {
    const [filteredSellers, setFilteredSellers] = useState([]);
    const { logout } = useAuth();
    const navigate = useNavigate();

    const [isFollowing, setIsFollowing] = useState(follow_status || false);

    // Function to filter sellers based on is_internal property
    const filterSellers = (sellers) => {
        return sellers.filter((seller) => seller.is_internal === 1); // Handle integer value
    };

    useEffect(() => {
        if (!loading) {
            const internalSellers = filterSellers(storeSellers);
            setFilteredSellers(internalSellers);
        }
    }, [storeSellers, loading]);

    // Function to handle follow/unfollow button click
    const handleFollowToggle = async () => {
        try {
            const apiToken = localStorage.getItem('apiToken');
            const endpoint = isFollowing ? '/follow/unfollow_user' : '/follow/follow_user';
            const payload = {
                api_token: apiToken,
                [isFollowing ? 'user_following_uuid' : 'following_uuid']: storeUuid,
            };

            const response = await api.post(endpoint, payload);

            if (response.status === 200 || response.status === 201) {
                if (response.data.status === 200 || response.data.message === "Already following") {
                    // Successfully followed/unfollowed based on the API response
                    setIsFollowing(!isFollowing); // Toggle follow status
                } else {
                    alert(`Action could not be completed: ${response.data.message}`);
                }
            }
            else if (response.status === 401) {
                logout();
                navigate('/home')

            }
            else {
                throw new Error(`HTTP error! status: ${response.status}, message: ${response.data.message}`);
            }
        } catch (error) {
            console.error('Error during follow/unfollow:', error);
        }
    };

    // Function to handle share button click
    const handleShare = async () => {
        try {
            console.log(storeId,'storeId');

            const dynamicLink = await StoreDynamicLink(storeId,storeName, thumburldata);
                 console.log(dynamicLink,'dynamicLink');
                //  return;
            if (navigator.share) {
              await navigator.share({
                title: "Seashels",
                text: "Profile of " + storeName,
                url: dynamicLink,
              });
              console.log("Link shared successfully");
            } else {
              // Fallback: copy link or display it
              console.log("Web Share API not supported, copying link...");
              navigator.clipboard.writeText(dynamicLink);
              alert("Link copied to clipboard: " + dynamicLink);
            }
          } catch (error) {
            console.error("Error creating or sharing dynamic link:", error);
          }
    };
    return (
        <div className="store-videos">
            <div className="container">
                {loading ? (
                    <>
                        <Skeleton height={30} width={200} />
                        <Skeleton count={3} height={150} />
                    </>
                ) : (
                    <>
                        <div className="store-sec-videos">
                            <h1>{storeName}</h1>
                            <div className="follow-share-sec">
                                <ul>
                                    <li>
                                        <a
                                            href="#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                handleFollowToggle(); // Toggle follow/unfollow status for the store
                                            }}
                                        >
                                            <img
                                                src="/images/add.png"
                                                alt={isFollowing ? "Unfollow" : "Follow"}
                                            />
                                            {isFollowing ? 'Unfollow' : 'Follow'}
                                        </a>
                                    </li>
                                    <li className='share'>
                                        <a
                                            href="#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                handleShare(); // Share the store
                                            }}
                                        >
                                            <img src="/images/shop-share.svg" alt="Share" />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        {video_data && video_data.length > 0 ? (  // Corrected this line
                            <ul>
                                {video_data.map((item) => (
                                    <li key={item.category_id}>
                                        <div className="img-box">
                                            <Link
                                                to="#"
                                                onClick={() => onProductClick(item.streaming_url)}
                                            >
                                                <img
                                                    src={item.thumbnail || 'https://via.placeholder.com/150'}
                                                    alt={item.name}
                                                />
                                            </Link>

                                            <Link
                                                className="trigger"
                                                to="#"
                                                onClick={() => onProductClick(item.streaming_url)}
                                            >
                                                {/* Optional: add text or icon inside Link if needed */}
                                            </Link>
                                        </div>
                                        <p>{item.description}</p>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <p style={{ display: 'none' }}>No videos available</p>
                        )}
                    </>
                )}

            </div>
        </div>
    );
};

export default StoreVideos;
