import React from 'react';
import './MultiSelector.css';

const ClearAllFilterData = ({ 
    onClearFilters, 
    setSelectedColors, 
    setSelectedSizes, 
    setSelectedPrice, 
    setSelectedPriceRange,
    setSelectedSort 
}) => {
    // Handle clearing the selection
    const clearSelection = () => {
        onClearFilters();
        setSelectedColors([]);
        setSelectedSizes([]);
        setSelectedPrice([]);
        setSelectedPriceRange([]);
        setSelectedSort('');
    };

    return (
        <div className="clear">
            <button onClick={clearSelection}>
                <span>CLEAR</span>
            </button>
        </div>
    );
};

export default ClearAllFilterData;
