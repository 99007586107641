import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
// import './LatestArrivals.css';
import api from '../../api';
import Modal from '../modal/Modal';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import StoreBanner from './StoreBanner';
import StoreVideos from './StoreVideos';
import StoreFilter from './StoreFilter';

const StoreProfile = ({ apiEndpoint = '/store/get_store', pageTitle = '' }) => {
    const [storeData, setStoreData] = useState(null);
    const [videoUrl, setVideoUrl] = useState('');
    const [bannerurl, setBannerurl] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [filterOptions, setFilterOptions] = useState({
        sizeOptions: [],
        priceOptions: [],
        colorOptions: [],
    });

    const { tag_id, tag_type, store_uuid } = useParams();

    const fetchStoreData = useCallback(async () => {
        try {
            setLoading(true);
            const api_token = localStorage.getItem('apiToken');
            const response = await api.get(apiEndpoint, {
                params: { api_token, tag_id, tag_type, store_uuid },
            });

            if (response.status === 200) {
                setStoreData(response.data);
                // Initialize filter options
                setFilterOptions({
                    sizeOptions: response.data.size_options,
                    priceOptions: response.data.price_options,
                    colorOptions: response.data.color_options,
                });
            } else {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    }, [apiEndpoint, tag_id, tag_type, store_uuid]);

    useEffect(() => {
        fetchStoreData();
    }, [fetchStoreData]);

    useEffect(() => {
        if (storeData) {
            if (store_uuid === 'cd6d5d086e89433fa83af7dec61b5dbf') {
                setBannerurl('https://static.ticimax.cloud/cdn-cgi/image/width=1519,quality=99/62682/uploads/sayfatasarim/sayfa5/abiye-b84bd651-f.jpg');
            } else {
                setBannerurl(storeData.store_logo_web);
            }
        }
    }, [storeData, store_uuid]);

    const handleProductClick = (videoUrl) => {
        setVideoUrl(videoUrl);
        setModalOpen(true);
    };

    const handleTabSelection = (selectedTab) => {
        // Update filter options based on the selected tab
        const { sizeOptions, priceOptions, colorOptions } = getFiltersForTab(selectedTab);
        setFilterOptions({ sizeOptions, priceOptions, colorOptions });
    };

    const getFiltersForTab = (tab) => {
        // Implement this function based on your tab and filter logic
        switch (tab) {
            case 'Women':
                return {
                    sizeOptions: [/* options for Women */],
                    priceOptions: [/* options for Women */],
                    colorOptions: [/* options for Women */],
                };
            case 'Men':
                return {
                    sizeOptions: [/* options for Men */],
                    priceOptions: [/* options for Men */],
                    colorOptions: [/* options for Men */],
                };
            case 'Unisex':
                return {
                    sizeOptions: [/* options for Unisex */],
                    priceOptions: [/* options for Unisex */],
                    colorOptions: [/* options for Unisex */],
                };
            default:
                return {
                    sizeOptions: [],
                    priceOptions: [],
                    colorOptions: [],
                };
        }
    };

    if (loading) {
        return (
            <div className="main-content not-home">
                <Skeleton height={600} />
                <Skeleton count={5} height={100} />
                <Skeleton count={5} height={100} />
                <Skeleton height={200} />
                <Skeleton count={5} height={100} />
            </div>
        );
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!storeData) {
        return <div>No store data available</div>;
    }

    return (
        <div className="main-content not-home">
            <StoreBanner bannerUrl={storeData.store_logo_web} thumburldata={storeData.store_logo_thumb_web} loading={loading} />
            <StoreVideos
                storeName={storeData.store_name}
                thumburldata={storeData.store_logo_thumb_web}
                video_data={storeData.video_data}
                onProductClick={handleProductClick}
                storeUuid={storeData.store_user_uuid}
                storeSellers={storeData.store_sellers}
                follow_status={storeData.is_following}
                storeId={store_uuid}

                loading={loading}
            />
            <StoreFilter
                priceRange={storeData.price_range}
                genderList={storeData.gender_list}
                storeUuid={storeData.store_uuid}
                loading={loading}
                onTabSelection={handleTabSelection}
            />
            <Modal
                isOpen={modalOpen}
                onClose={() => setModalOpen(false)}
                videoUrl={videoUrl}
            />
        </div>
    );
};

export default StoreProfile;
