import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useCurrency } from '../../context/CurrencyContext';
import { useAuth } from '../../context/AuthContext';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import api from '../../api';
import LazyLoadImage from '../LazyLoadImage';
import LoadMore from '../LoadMore/LoadMore';

const ITEMS_PER_PAGE = 54;
const defaultImage = 'https://via.placeholder.com/150';

const ShopFilterProductListing = ({
    storeUuid,
    sortOption,
    sizeOption,
    priceOption,
    colorOption,
    segmentId,
    genderId,
    categoryList,
    sub_category_list,
    productData,
    onClearFilters,
    loading: propLoading,
}) => {
    const { logout } = useAuth();
    const navigate = useNavigate();
    const { selectedCurrency } = useCurrency();

    const [activeTab, setActiveTab] = useState('All');
    const [loading, setLoading] = useState(propLoading);
    const [products, setProducts] = useState([]);
    const [error, setError] = useState(null);
    const [loadedItemCount, setLoadedItemCount] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [loadingMore, setLoadingMore] = useState(false);
    const [isSuiData, setIsSuiData] = useState(false);

    const fetchProducts = async (append = false) => {
        try {
            if (!append) {
                setLoading(true);
                setLoadedItemCount(0);
            } else {
                setLoadingMore(true);
            }

            const api_token = localStorage.getItem('apiToken');
            const currencyCode = localStorage.getItem('currencyCode');

            const params = {
                api_token,
                page: Math.floor(loadedItemCount / ITEMS_PER_PAGE),
                size_list: sizeOption,
                color_list: colorOption,
                price_min: priceOption ? priceOption.split('-')[0] : '',
                price_max: priceOption ? priceOption.split('-')[1] : '',
                segment_id: segmentId,
                gender_id: genderId,
                category_list: categoryList,
                currency_code: currencyCode,
                sort_order: sortOption,
                store_uuid:'',
                sub_category_list:sub_category_list
            };
            // https://global.seashels.com:7777/api/filters/get_skus_for_filter?api_token=00853806ce4d4ff18354c6c368d3e6d8&page=0&store_uuid=&segment_id=1&gender_id=2&category_list=309&currency_code=USD&sort_order=&size_list=&color_list=&price_min=&price_max=&sub_category_list=121
            const response = await api.get('/filters/get_skus_for_filter', { params });
            if (response.status === 401) {
                logout();
                navigate('/home');
            } else if (response.status === 200) {
                const data = response.data;
                const processedProducts = data.sui_data || data.sku_data || [];
                const totalItems = data.total_count;

                // Check if sui_data is empty, and stop loading if it is
                if (!processedProducts.length) {
                    setProducts([]);
                    setLoadedItemCount(0);
                    setTotalItems(0);
                    setIsSuiData(false);
                } else {
                    setProducts((prevProducts) =>
                        append ? [...prevProducts, ...processedProducts] : processedProducts
                    );

                    const newLoadedCount = append ? loadedItemCount + processedProducts.length : processedProducts.length;
                    setLoadedItemCount(newLoadedCount);
                    setTotalItems(totalItems);
                    setIsSuiData(!!data.sui_data);
                }
            } else {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
        } catch (error) {
            setError('Failed to fetch products');
        } finally {
            setLoading(false);
            setLoadingMore(false);
        }
    };

    useEffect(() => {
        if (!productData) {
            fetchProducts(false);
        } else {
            const productsData = productData.sui_data || [];
            setProducts(productsData);
            setTotalItems(productData.total_count || 0);
            setLoadedItemCount(productsData.length || 0);
            setLoading(false); // Stop loading if `productData` is provided
        }
    }, [
        storeUuid,
        sortOption,
        sizeOption,
        priceOption,
        colorOption,
        segmentId,
        genderId,
        categoryList,
        productData,
    ]);

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };

    const handleProductClick = (productSui) => {
        navigate(`/product/${productSui}`);
    };

    const loadMoreProducts = () => {
        fetchProducts(true);
    };

    if (loading && products.length === 0) {
        return (
            <div id="filter-products">
                <div className="container">
                    <ul className="skeleton-list">
                        {Array.from({ length: 8 }).map((_, index) => (
                            <li key={index}>
                                <Skeleton height={400} width={300} />
                                <h4><Skeleton width={200} /></h4>
                                <div className="price-value">
                                    <Skeleton width={100} />
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        );
    }

    if (error) {
        return <div className="error">{error}</div>;
    }

    return (
        <div id="filter-products">
            <div className="container">
                <div className="also-like latest uniquelist">
                    {products.length > 0 ? (
                        <ul>
                            {products.map((product) => {
                                const cacheBuster = new Date().getTime();
                                const imageUrlWithCacheBuster = `${product.image_url || product.image_url_thumb || defaultImage}?cb=${cacheBuster}`;
                                const SUI_data = isSuiData ? product.SUI : product.sui;
                                const title = isSuiData ? product.product_title : product.sku_title;
                                const total_price = isSuiData ? product.display_final_price : product.display_final_price;
                                const strike_price = isSuiData ? product.display_total_price : product.display_total_price;
                                const discount = isSuiData ? product.platform_discount : product.sku_discount;
                                const currency_symbol = isSuiData ? product.display_currency_symbol : product.display_currency_symbol;

                                return (
                                    <li key={`${SUI_data || product.id}-${imageUrlWithCacheBuster}`}>
                                        <Link to={`/product/${product.SUI || product.sui}`} className='img-box'>
                                            <LazyLoadImage
                                                key={product.image_url || product.image_url_thumb}
                                                thumbnailSrc={product.image_url || product.image_url_thumb}
                                                fullImageSrc={product.image_url || product.image_url_thumb}
                                                alt={title || 'Product image'}
                                                onError={(e) => (e.target.src = defaultImage)}
                                            />
                                            {product.in_stock === 0 && (
                                                <span className="sold-out">Sold Out</span>
                                            )}
                                        </Link>
                                        <h4 className='store_name'>
                                            {product.store_name}
                                        </h4>
                                        <div className="description content">
                                            <h4>
                                                <Link to={`/product/${product.SUI || product.sui}`}>{product.product_title || product.sku_title}</Link>
                                            </h4>
                                            <div className="price-value">
                                                {strike_price && strike_price !== total_price && (
                                                    <>
                                                        <span className="strike_price">
                                                            {`${currency_symbol || '$'}${strike_price}`}
                                                        </span>
                                                        <span className="final_price">
                                                            {`${currency_symbol || '$'}${total_price}`}
                                                        </span>
                                                        <span className="discount">
                                                            {`${product.platform_discount || product.sku_discount}% off`}
                                                        </span>
                                                    </>
                                                )}
                                                {(!strike_price || strike_price === total_price) && (
                                                    <span className="final_price">
                                                        {`${currency_symbol || '$'}${total_price}`}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                    ) : (
                        <div className="no-products">No products available</div>
                    )}
                </div>

                {/* Render the LoadMore component */}
                <LoadMore
                    loadedItemCount={loadedItemCount}
                    totalItems={totalItems}
                    onLoadMore={loadMoreProducts}
                    loadingMore={loadingMore}
                />
            </div>
        </div>
    );
};

export default ShopFilterProductListing;
