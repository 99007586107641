import React from 'react';

// Function to create the product dynamic link
const StoreDynamicLink = async (store_uuid, store_name, store_profile_image) => {
  const image = encodeURIComponent(store_profile_image.trim());  // Encode and trim the image URL if necessary
  const description = encodeURIComponent(`Profile of ${store_name}`.trim());
  const title = "Seashels";

  console.log(store_name, store_profile_image);

  const currentType = localStorage.getItem('userRole') || 'guest';  // Fallback if null
  const user_uuid = localStorage.getItem('userUuid');
  const country_code = localStorage.getItem('userCountryCode') || 'US';

  // Firebase Dynamic Links API URL with your Firebase API key
  const API_KEY = "AIzaSyBmAr5tphOF7t3V48143Htw0G535wOP5xM";  // Replace this with your Firebase Web API Key
  const url = `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${API_KEY}`;
  console.log(`https://connect.seashels.com/api/v1.0/Link/profile?user_id=${user_uuid.trim()}&type=${currentType.trim()}&region=${country_code.trim()}&mediaUrl=${image}&description=${description}`, 'store_profile_image');

  // Dynamic link configuration object
  const dynamicLinkConfig = {
    dynamicLinkInfo: {
      link:`https://connect.seashels.com/api/v1.0/Link/profile?user_id=${store_uuid.trim()}&type=${currentType.trim()}&region=${country_code.trim()}&mediaUrl=${image}&description=${description}`,  // Product URL with parameters
      domainUriPrefix: "https://discover.seashels.com",  // Your Firebase Dynamic Link domain
      androidInfo: {
        androidPackageName: "com.seashels.discover",   // Android package name
        androidFallbackLink: "https://connect.seashels.com",  // Fallback URL if app isn't installed
        androidMinPackageVersionCode: "1",             // Minimum version of Android app (as a string)
      },
      iosInfo: {
        iosBundleId: "com.seashels.discover",          // iOS bundle ID
        iosFallbackLink: "https://connect.seashels.com",  // Fallback URL if app isn't installed
        iosAppStoreId: "6463077820",                   // App Store ID for iOS
        iosMinimumVersion: "1.0",                      // Minimum iOS version (correct field)
      },
      socialMetaTagInfo: {
        socialTitle: title || "Check out this Product!",   // Title for social sharing
        socialDescription: decodeURIComponent(description) || "This is an amazing product you should check out!",
        socialImageLink: store_profile_image.trim()  // Use the original image for social sharing and trim it
      }
    },
    suffix: {
      option: "SHORT"  // Generates a short dynamic link
    }
  };

  try {
    console.log(url, 'url');
    // Making a POST request to Firebase Dynamic Links API
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dynamicLinkConfig),
    });

    // Check for non-200 HTTP response
    if (!response.ok) {
      const errorResponse = await response.json();
      console.error("Error response:", errorResponse);
      throw new Error(`Error creating dynamic link: ${errorResponse.error.message}`);
    }

    // Parsing the API response
    const result = await response.json();
    console.log("Generated Dynamic Link:", result.shortLink);
    return result.shortLink;  // Returning the short dynamic link
  } catch (error) {
    console.error("Error creating dynamic link:", error);
  }
};

export default StoreDynamicLink;
