import React, { useState, useEffect } from 'react';
import { useParams,useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../context/AuthContext'; 
// import './LatestArrivals.css';
import api from '../api';
import Pagination from './pagination/Pagination';
import Modal from './modal/Modal'; // Import Modal component
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const ITEMS_PER_PAGE = 54; // Number of items per page

const LiveSocialMain = ({
    apiEndpoint = '/tags/get_list_for_tag',
    pageTitle = '',
}) => {
    const { logout } = useAuth();
    const navigate = useNavigate();

    const { tag_id, tag_type } = useParams();
    const [products, setProducts] = useState([]);
    const [originalProducts, setOriginalProducts] = useState([]);
    const [tagList, setTagList] = useState([]); // State for holding the tag list
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [selectedTag, setSelectedTag] = useState('all'); // State for the selected tag filter
    const [modalOpen, setModalOpen] = useState(false); // State to control modal visibility
    const [videoUrl, setVideoUrl] = useState(''); // State to store the video URL
    const defaultImage = 'https://via.placeholder.com/150';

    // Fetch products on mount and when filters change
    useEffect(() => {
        const fetchProducts = async (pageNumber = 1) => {
            try {
                setLoading(true);
                const api_token = localStorage.getItem('apiToken');
                const response = await api.get(apiEndpoint, {
                    params: {
                        api_token: api_token,
                        tag_type: 'video',
                        page: pageNumber - 1, // Fetch first page initially
                        per_page: ITEMS_PER_PAGE
                    },
                });
                if (response.data.status === 401) {
                    logout();
                    navigate('/home')

                }
                else if (response.data.status !== 200) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = response.data;

                // Extract and set the tag list
                const tags = data.tag_list || [];
                setTagList(tags);

                const processedProducts = data.tags_data?.map((item) => ({
                    id: item.reference_id,
                    image: item.video_thumbnail || defaultImage,
                    alt: item.tag_name,
                    title: item.store_name,
                    video_description: item.video_description,
                    category: item.tag_name, // Using tag_name as the category for filtering
                    sui: item.store_uuid,
                    videoUrl: item.video_url, // Store the video URL
                })) || [];

                setProducts(processedProducts);
                if (currentPage === 1) {
                    setOriginalProducts(processedProducts); // Set original products only on the first fetch
                }
                setTotalPages(Math.ceil(data.tags_items_total_count / ITEMS_PER_PAGE));
                // updateCurrentPageProducts(processedProducts, currentPage);

            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };
        fetchProducts(currentPage);
    }, [apiEndpoint, tag_id, tag_type, currentPage]);

    // Update products displayed on the current page
    const updateCurrentPageProducts = (productsList, pageNumber) => {
        setProducts(productsList);
    };

    // Handle clicking a product to open the modal
    const handleProductClick = (videoUrl) => {
        setVideoUrl(videoUrl);
        setModalOpen(true);
    };

    // Handle clicking a tag to filter products
    const handleTagFilterClick = (tag) => {
        setSelectedTag(tag.tag_id);
        const filteredProducts = tag.tag_id === 'all'
            ? originalProducts
            : originalProducts.filter(product => product.category === tag.tag_name);

        // Update the total pages and reset to the first page
        setTotalPages(Math.ceil(filteredProducts.length / ITEMS_PER_PAGE));
        setCurrentPage(1); // Reset to the first page
        updateCurrentPageProducts(filteredProducts, 1); // Update products for the first page
    };

    // Handle page changes
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    if (loading) {
        return (
            <div className="main-content">
                <div className="wear-offer store-videos live-social">
                    <div className="container">
                        <Skeleton height={40} width={200} />
                        <div id="filter-products">
                            <ul>
                                <li><Skeleton width={100} /></li>
                                <li><Skeleton width={100} /></li>
                                <li><Skeleton width={100} /></li>
                                <li><Skeleton width={100} /></li>
                            </ul>
                        </div>
                        <Skeleton count={6} height={200} />
                        <Skeleton height={40} />
                    </div>
                </div>
            </div>
        );
    }

    if (error) {
        return <p>Error: {error}</p>;
    }

    return (
        <div className="main-content">
            <div className="wear-offer store-videos live-social">
                <div className="container">
                    <h2>{pageTitle}</h2>
                    {/* Filter Products */}
                    <div id="filter-products">
                        <ul>
                            <li className={selectedTag === 'all' ? 'active' : ''}>
                                <a href="#" onClick={() => handleTagFilterClick({ tag_id: 'all', tag_name: 'All' })}>All</a>
                            </li>
                            {tagList.map((tag) => (
                                <li key={tag.tag_id} className={selectedTag === tag.tag_id ? 'active' : ''}>
                                    <a href="#" onClick={() => handleTagFilterClick(tag)}>{tag.tag_name}</a>
                                </li>
                            ))}
                        </ul>
                    </div>
                    {/* Product Listing */}
                    <ul className="product-list">
                        {products.length > 0 ? (
                            products.map((product) => (
                                <li key={product.id}>
                                    <div className="img-box">
                                        <Link to="#" onClick={() => handleProductClick(product.videoUrl)} >
                                            <img
                                                src={product.image || defaultImage}
                                                alt={product.alt}
                                                onError={(e) => (e.target.src = defaultImage)}
                                            />
                                        </Link>
                                        <a href="#" className="trigger" onClick={() => handleProductClick(product.videoUrl)}></a>
                                    </div>
                                    <div className="heading-text">
                                        <h4>{product.title}</h4>
                                    </div>
                                    <p>{product.video_description}</p>
                                </li>
                            ))
                        ) : (
                            <p>No products available</p>
                        )}
                    </ul>
                    {/* Pagination */}
                    <Pagination
                        totalPages={totalPages}
                        currentPage={currentPage}
                        onPageChange={handlePageChange}
                    />
                </div>
            </div>
            {/* Modal for Video Playback */}
            <Modal
                isOpen={modalOpen}
                onClose={() => setModalOpen(false)}
                videoUrl={videoUrl}
            />
        </div>
    );
};

export default LiveSocialMain;
