// Logo.js
import React from 'react';

const Logo = () => (
    <div className="logo account-sign">
        <a href="/">
            <img src="/images/logo.png" alt="Logo" />
        </a>
    </div>
);

export default Logo;
