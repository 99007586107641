// SkeletonLoader.jsx
import React from 'react';

const SkeletonLoader = () => {
    return (
        <div className="skeleton-loader">
            <div className="skeleton-item" style={{ height: '520px', marginBottom: '10px' }} />
            <div className="skeleton-item" style={{ height: '50px', marginBottom: '10px' }} />
            <div className="skeleton-item" style={{ height: '50px', marginBottom: '10px' }} />
            <div className="skeleton-item" style={{ height: '50px', marginBottom: '10px' }} />
        </div>
    );
};

export default SkeletonLoader;
