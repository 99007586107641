import React, { createContext, useState, useEffect, useCallback } from 'react';
import api from '../api';
import { getClientIp } from '../utils/getClientIp';
import { useCart } from './CartContext';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [apiToken, setApiToken] = useState(null);
    const [userRole, setUserRole] = useState(null);
    const [deviceToken, setDeviceToken] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const cartContext = useCart();
    const { setCartCount } = cartContext || {};

    // Memoize clientId to avoid redundant recalculation
    const getClientId = useCallback(async () => {
        let clientId = localStorage.getItem('clientId');
        if (!clientId) {
            clientId = await getClientIp();
            localStorage.setItem('clientId', clientId);
        }
        return clientId;
    }, []);

    useEffect(() => {
        const initializeAuth = async () => {
            setLoading(true);
            try {
                const storedToken = localStorage.getItem('apiToken');
                const storedRole = localStorage.getItem('userRole');
                const storedCurrencyCode = localStorage.getItem('currencyCode');
                const clientId = await getClientId();

                if (!storedCurrencyCode) {
                    localStorage.setItem('currencyCode', 'USD');
                    localStorage.setItem('currencyId', '2');
                }

                // If apiToken exists, no need to call guest login API
                if (storedToken && storedToken !== 'null') {
                    setApiToken(storedToken);
                    setUserRole(storedRole);
                    setDeviceToken(clientId);
                    setLoading(false);
                    return;
                }

                // Perform guest login only if no valid token exists
                const guestLoginResponse = await api.post('/guest/guest_login', {
                    app_version: '1.0',
                    country_code: 'US',
                    device_token: clientId || '',
                    device_type: 'web',
                });

                if (guestLoginResponse.data && guestLoginResponse.data.api_token) {
                    const newApiToken = guestLoginResponse.data.api_token;
                    setApiToken(newApiToken);
                    setUserRole('guest');
                    setDeviceToken(clientId);
                    localStorage.setItem('apiToken', newApiToken);
                    localStorage.setItem('userRole', 'guest');

                    // Create cart after guest login
                    const cartResponse = await api.post('/cart/create_cart', {
                        api_token: newApiToken,
                    });

                    if (cartResponse.data && cartResponse.data.cart_data) {
                        const { cart_id, order_num, item_count } = cartResponse.data.cart_data;

                        localStorage.setItem('cart_id', cart_id);
                        localStorage.setItem('order_num', order_num);
                        localStorage.setItem('item_count', item_count);
                        localStorage.setItem('cartCount', item_count);

                        if (typeof item_count === 'number' && !isNaN(item_count)) {
                            if (setCartCount) setCartCount(item_count);
                        } else {
                            console.error('Invalid item_count:', item_count);
                        }
                    } else {
                        console.error('Cart data is missing:', cartResponse.data);
                    }
                }
            } catch (err) {
                console.error('Initialization failed:', err);
                setError('Initialization failed.');
            } finally {
                setLoading(false);
            }
        };

        initializeAuth();
    }, [getClientId, setCartCount]); // Use getClientId as a dependency to avoid rerunning

    const login = (token, role) => {
        setApiToken(token);
        setUserRole(role);
        localStorage.setItem('apiToken', token);
        localStorage.setItem('userRole', role);
    };

    const logout = async () => {
        setApiToken(null);
        setUserRole(null);
        localStorage.removeItem('apiToken');
        localStorage.removeItem('userRole');

        try {
            const clientId = await getClientId();

            // Perform guest login after logout
            const guestLoginResponse = await api.post('/guest/guest_login', {
                app_version: '1.0',
                country_code: 'US',
                device_token: clientId || '',
                device_type: 'web',
            });

            if (guestLoginResponse.data && guestLoginResponse.data.api_token) {
                const newApiToken = guestLoginResponse.data.api_token;
                setApiToken(newApiToken);
                setUserRole('guest');
                setDeviceToken(clientId);
                localStorage.setItem('apiToken', newApiToken);
                localStorage.setItem('userRole', 'guest');
                
                // Create cart after guest login
                const cartResponse = await api.post('/cart/create_cart', {
                    api_token: newApiToken,
                });

                if (cartResponse.data && cartResponse.data.cart_data) {
                    const { cart_id, order_num, item_count } = cartResponse.data.cart_data;

                    localStorage.setItem('cart_id', cart_id);
                    localStorage.setItem('order_num', order_num);
                    localStorage.setItem('item_count', item_count);
                    localStorage.setItem('cartCount', item_count);

                    if (typeof item_count === 'number' && !isNaN(item_count)) {
                        if (setCartCount) setCartCount(item_count);
                    } else {
                        console.error('Invalid item_count:', item_count);
                    }
                } else {
                    console.error('Cart data is missing:', cartResponse.data);
                }
            }
        } catch (err) {
            console.error('Guest login after logout failed:', err);
            setError('Guest login after logout failed.');
        }
    };

    return (
        <AuthContext.Provider value={{ apiToken, userRole, deviceToken, loading, error, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => React.useContext(AuthContext);

export default AuthContext;
