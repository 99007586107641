import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import api from '../../api';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import LoadMore from '../LoadMore/LoadMore';
import { useAuth } from '../../context/AuthContext';

const ITEMS_PER_PAGE = 54;

const Deals = ({
    apiEndpoint = '/tags/get_edit_list',
    pageTitle = ''
}) => {
    const { logout } = useAuth();
    const { tag_id, tag_type } = useParams();
    const [products, setProducts] = useState([]);
    const [originalProducts, setOriginalProducts] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [loadingMore, setLoadingMore] = useState(false);
    const [loadedItemCount, setLoadedItemCount] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [activeTag, setActiveTag] = useState(null);
    const [tagList, setTagList] = useState([]);
    const defaultImage = 'https://via.placeholder.com/150';
    const navigate = useNavigate();

    const fetchProducts = async (append = false) => {
        try {
            if (!append) {
                setLoading(true);
            }
            setLoadingMore(true);

            const api_token = localStorage.getItem('apiToken');
            const response = await api.get(apiEndpoint, {
                params: {
                    api_token: api_token,
                    tag_type: 'edit',
                    page: Math.floor(loadedItemCount / ITEMS_PER_PAGE) + 1,
                    per_page: ITEMS_PER_PAGE,
                },
            });

            if (response.data.status === 401) {
                logout();
                navigate('/home');
            } else if (response.status !== 200) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = response.data;

            const processedProducts = data.edit_list?.map((item) => ({
                id: item.id,
                image: item.media_url_thumbnail || defaultImage,
                media_url: item.media_url || defaultImage,
                alt: item.tag_name,
                title: item.tag_name,
                tag_id: item.tag_id,
                type_data: item.type,
                button_text: item.button_text,
                button_screen: item.button_screen,
                heading_align: item.heading_align,
                heading_1: item.heading_1,
                button_color: item.button_color,
                sui: item.sui,
            })) || [];

            setProducts((prevProducts) =>
                append ? [...prevProducts, ...processedProducts] : processedProducts
            );
            if (!append) {
                setOriginalProducts(processedProducts);
            }

            setLoadedItemCount((prevCount) => prevCount + processedProducts.length);
            setTotalItems(data.edit_items_total_count);

            const allTags = [{ tag_id: null, tag_name: 'All' }, ...data.tag_list];
            setTagList(allTags);
            setActiveTag(null);

        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(true);
            setLoadingMore(false);
        }
    };

    useEffect(() => {
        fetchProducts();
    }, [apiEndpoint, tag_id, tag_type]);

    const handleProductClick = (sui) => {
        navigate(`/latest-arrivals/${sui}`);
    };

    const handleTagFilter = (tag) => {
        setActiveTag(tag.tag_id);
        const filteredProducts = tag.tag_id === null
            ? originalProducts
            : originalProducts.filter(product => product.tag_id === tag.tag_id);
        setProducts(filteredProducts);
        setLoadedItemCount(filteredProducts.length);
        setTotalItems(filteredProducts.length);
    };

    const loadMoreProducts = () => {
        fetchProducts(true);
    };

    if (loading && loadedItemCount === 0) {
        return (
            <div className="main-content deals">
                <div className="container">
                    {/* Skeleton for the Page Title */}
                    <Skeleton height={40} width={200} />
                    <div className="tag-filter" id="filter-products">
                        {/* Skeleton for Tag Filters */}
                        <ul>
                            {Array.from({ length: 5 }).map((_, index) => (
                                <li key={index}><Skeleton width={100} height={30} /></li>
                            ))}
                        </ul>
                    </div>
                </div>

                {/* Skeleton for Product Listing */}
                <div className="pop-up-content">
                    <div className="container">
                        <ul>
                            {Array.from({ length: 12 }).map((_, index) => (
                                <li key={index} className="product-item">
                                    <Skeleton height={450} width={350} />
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>

                {/* Skeleton for Load More Button */}
                <div className="container">
                    <Skeleton height={40} width={150} />
                </div>
            </div>
        );
    }

    if (error) {
        return <p>Error: {error}</p>;
    }

    return (
        <div className="main-content deals">
            <div className="container">
                <h2>{pageTitle}</h2>
                <div className="tag-filter" id="filter-products">
                    <ul>
                        {tagList.map(tag => (
                            <li
                                key={tag.tag_id}
                                className={tag.tag_id === activeTag ? 'active' : ''}
                                onClick={() => handleTagFilter(tag)}
                            >
                                {tag.tag_name}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>

            {/* Product Listing */}
            <div className="pop-up-content">
                <div className="container">
                    <ul>
                        {products.map((product) => (
                            <li key={product.id} className="product-item">
                                <Link to={`/latest-arrivals/${product.tag_id}/${product.type_data}`}>
                                    <img
                                        src={product.image}
                                        alt={product.alt}
                                    />
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>

            {/* Conditionally Render Load More Button */}
            {loadedItemCount < totalItems && (
                <LoadMore
                    loadedItemCount={loadedItemCount}
                    totalItems={totalItems}
                    onLoadMore={loadMoreProducts}
                    loadingMore={loadingMore}
                />
            )}
        </div>
    );
};

export default Deals;
