import React, { useState, useEffect } from 'react';
import { useParams,useNavigate } from 'react-router-dom';

import api from '../../api'; // Ensure this uses Axios
import { useAuth } from '../../context/AuthContext'; 
import productDynamicLink from './productDynamicLink';
const ProductIcons = ({
    isWishlist: initialIsWishlist,
    isFavorite: initialIsFavorite,
    videoData,
    storeUuid,
    itemId,
    color_id,
    size_id,
    systemName,
    imageData,
    description ,
   title,

}) => {

    const { logout } = useAuth();
    const navigate = useNavigate();
    const [shareLink, setShareLink] = useState('');

    const [isWishlist, setIsWishlist] = useState(initialIsWishlist);
    const [isFavorite, setIsFavorite] = useState(initialIsFavorite);
    const api_token = localStorage.getItem('apiToken');

    useEffect(() => {
        setIsWishlist(initialIsWishlist);
    }, [initialIsWishlist]);

    useEffect(() => {
        setIsFavorite(initialIsFavorite);
    }, [initialIsFavorite]);

    const handleApiCall = async (apiEndpoint, params) => {
        try {
            const response = await api.post(apiEndpoint, params);
             if (response.status === 401) {
                logout();
                navigate('/home');

            }
           else if (response.status !== 200) {
                throw new Error('API request failed');
            }
            return response.data;
        } catch (error) {
            console.error(`Error with API call to ${apiEndpoint}:`, error);
        }
    };

    const addToGift = async () => {
        return handleApiCall('/gift/add_item_to_gift_list', {
            api_token,
            color_id: color_id.color_id.toString(),
            influencer_user_id: '0',
            size_id: size_id.size_id.toString(),
            store_uuid: storeUuid,
            sui: itemId,
            system_name: systemName,
        });
    };

    const removeFromGift = async () => {
        return handleApiCall('/gift/remove_gift_item', {
            api_token,
            sui: itemId,
        });
    };

    const addToFavorite = async () => {
        return handleApiCall('/favorite/set_favorite', {
            api_token,
            reference_id: itemId,
            type: 'product',
            influencer_user_id: "0",
            store_uuid: storeUuid,
            system_name: systemName,
        }

        );
    };

    const removeFromFavorite = async () => {
        return handleApiCall('/favorite/remove_favorite', {
            api_token,
            reference_id: itemId,
            type: 'product',
        });
    };

    const handleWishlistToggle = async (event) => {
        event.preventDefault();
        const result = isWishlist ? await removeFromGift() : await addToGift();
        if (result) {
            setIsWishlist(!isWishlist);
        }
    };

    const handleFavoriteToggle = async (event) => {
        event.preventDefault();
        const result = isFavorite ? await removeFromFavorite() : await addToFavorite();
        if (result) {
            setIsFavorite(!isFavorite);
        }
    };

    const handleShare = async () => {
    
        try {
          const dynamicLink = await productDynamicLink(itemId, systemName, storeUuid, imageData, description, title);
               console.log(dynamicLink,'dynamicLink');
          if (navigator.share) {
            await navigator.share({
              title: title,
              text: description,
              url: dynamicLink,
            });
            console.log("Link shared successfully");
          } else {
            // Fallback: copy link or display it
            console.log("Web Share API not supported, copying link...");
            navigator.clipboard.writeText(dynamicLink);
            alert("Link copied to clipboard: " + dynamicLink);
          }
        } catch (error) {
          console.error("Error creating or sharing dynamic link:", error);
        }
      };
    
    return (
        <div className="product-icons">
            <div className="icons-row">
                <ul>
                    <li className={`gift ${isWishlist ? 'active' : 'inactive'}`}>
                        <a href="#" onClick={handleWishlistToggle}>

                        </a>
                    </li>

                    <li className="share" >
                        <a href="#" onClick={handleShare} className="icon-button">
                        </a>
                    </li>

                    <li className={`tag ${isFavorite ? 'active' : 'inactive'}`}>
                        <a href="#" onClick={handleFavoriteToggle}>

                        </a>
                    </li>

                    {videoData.length > 0 && (
                        <li className="watch">
                            <a href="#">
                            </a>
                        </li>
                    )}
                </ul>
                <div className="store-btn">
                    <ul>
                        <li>
                            <a href="#">
                                <span>
                                    <img src="/images/chat.png" alt="Talk to Store" />
                                </span>
                                Talk to Store
                            </a>
                        </li>
                      
                    </ul>
                    <div className='enter_store'>
                    
                            <a href={`/visit-store/${storeUuid}`}>
                                <span>
                                    <img src="/images/storefront.svg" alt="Enter Store" />
                                </span>
                                Enter Store
                            </a>
                        
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductIcons;
