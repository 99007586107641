// src/components/ProtectedRoute.js
import React, { useContext } from 'react';
import AuthContext from '../../context/AuthContext';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
    const { apiToken, loading, error } = useContext(AuthContext);
    const userRole = localStorage.getItem('userRole');
    if (error || !apiToken) {
        return <Navigate to="/login" />;
    }

    return (
        <>
            {/* Conditionally render based on user role */}
            {/* {userRole === 'guest' ? (
                <div>Guest Content</div>
            ) : (
                <div>User Content</div>
            )} */}
            {children}
        </>
    );
};

export default ProtectedRoute;
