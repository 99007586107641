// AlertPopup.js
import React from 'react';
import Alert from './Alert'; // Import the Alert component
import './AlertPopup.css'; // Ensure your CSS file is properly linked

const AlertPopup = ({ isOpen, onClose }) => {
    if (!isOpen) return null;

    return (
        <div className="alert-popup-overlay">
            <div className="alert-popup-content">
                <button className="alert-popup-close" onClick={onClose}>X</button>
                <div className="alert-popup-body">
                    <Alert />
                </div>
            </div>
        </div>
    );
};

export default AlertPopup;
