import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './LiveSocial.css';
import Modal from '../modal/Modal'; // Import the Modal component
import LazyLoadImage from '../LazyLoadImage'; // Import LazyLoadImage component

const LiveSocial = ({ videos, loading }) => {
    const [selectedVideo, setSelectedVideo] = useState(null);

    // Function to open the modal and set the selected video
    const openModal = (videoUrl) => setSelectedVideo(videoUrl);
    const closeModal = () => setSelectedVideo(null);

    return (
        <section className="live">
            <div className="container">
                {loading ? (
                    <>
                        <h2>
                            <Skeleton width={150} height={30} />
                        </h2>
                        <div className="social-carousel">
                            {[...Array(6)].map((_, index) => (
                                <div key={index} className="item">
                                    <div className="img-box">
                                        <Skeleton height={350} width={250} />
                                    </div>
                                    <div className="content">
                                        <Skeleton width={100} />
                                        <Skeleton width={150} />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </>
                ) : (
                    <>
                        <h2>{videos.heading}
                            {/* <Link to="/live-social" className="read-more"></Link> */}


                        </h2>
                        <div className="social-carousel">
                            {videos?.data?.carousel?.length > 0 ? (
                                videos.data.carousel.map((item, index) => (
                                    <div key={index} className="item">
                                        <div className="img-box">
                                            {/* <LazyLoadImage
                                                thumbnailSrc={item.video_thumbnail} 
                                                fullImageSrc={item.video_thumbnail_web} 
                                                alt={`Social Image ${index + 1}`}
                                                onClick={() => openModal(item.video_url)}
                                            /> */}
                                             <img
                                            src={item.video_thumbnail_web}
                                            alt={`Social Image ${index + 1}`}
                                            onClick={() => openModal(item.video_url)}
                                        />
                                        </div>
                                        <div className="content">
                                            <h3>
                                                <Link to={`/visit-store/${item.store_uuid}`}>
                                                    {item.store_name}
                                                </Link>
                                            </h3>
                                            <h4>{item.video_description}</h4>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <p>No videos available</p>
                            )}
                        </div>
                    </>
                )}

                {/* Modal for displaying the selected video */}
                <Modal
                    isOpen={!!selectedVideo}
                    onClose={closeModal}
                    videoUrl={selectedVideo}
                />
            </div>
        </section>
    );
};

export default LiveSocial;
