import React from 'react';
import { Link, Route, Routes, Outlet } from 'react-router-dom';
import Chat from './Chat';
const Tab1 = () => (
    <div id="tab-1">
        <nav className="tab-nav">
            <div className="tab active">
                <div className="top-block">
                    <div className="message-div">
                        <ul>
                            <li>
                                <Link to="messages">Messages <span className="badge">12</span></Link>
                            </li>
                        </ul>
                        <div className="add">
                            <a href="#"><img src="/images/add.png" alt="" /></a>
                        </div>
                    </div>
                    <form method="post">
                        <input type="text" className="textbox" placeholder="Search" />
                    </form>
                </div>
                <ul id="msg-list">
                    <li>
                        <Link to="sub-tab-1" className="users-name">
                            <div className="author">
                                <span><img src="/images/profile-1.png" alt="" /><span className="status green"></span></span>
                                <div className="author-details">
                                    <h6>Elmer Laverty</h6>
                                    <span className="letest-msg">Haha oh man 🔥</span>
                                </div>
                            </div>
                            <span className="min">12m</span>
                        </Link>
                    </li>
                    {/* More list items here */}
                </ul>
            </div>
        </nav>
        <Routes>
            <Route path="sub-tab-1" element={<SubTab1 />} />
            <Route path="sub-tab-2" element={<SubTab2 />} />
            <Route path="sub-tab-3" element={<SubTab3 />} />
        </Routes>
        <Outlet />
    </div>
);

const SubTab1 = () => (
    <div id="sub-tab-1">
        {/* Content for sub-tab-1 */}
    </div>
);

const SubTab2 = () => (
    <div id="sub-tab-2">
        <h2>This is the second sub tab!</h2>
    </div>
);

const SubTab3 = () => (
    <div id="sub-tab-3">
        <h2>This is the third sub tab!</h2>
    </div>
);

export default Tab1;
