import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import $ from 'jquery';

const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        $('html, body').scrollTop(0);
        $('#root').scrollTop(0);
    }, [pathname]);

    return null;
};

export default ScrollToTop;
